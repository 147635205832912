<button
  (click)="openMore($event)"
  class="absolute top-16 right-8 hover:opacity-50 active:opacity-100 transition duration-300"
>
  <app-icon class="" [icon]="'more'" class="h-20 w-20"></app-icon>
</button>
<ul *ngIf="talent.more" class="absolute px-16 top-40 right-16">
  <li class="px-16 hover:bg-body cursor-pointer">Mark as favorite</li>
  <li class="px-16 hover:bg-body cursor-pointer">Remove</li>
</ul>
<div class="flex">
  <div class="p-16">
    <div class="flex mb-16">
      <div class="w-64 h-64 overflow-hidden rounded-1/2">
        <img src="{{ talent.img }}" alt="" class="w-full h-full object-cover" />
      </div>
      <div class="ml-16">
        <h4 class="text-lg font-bold">{{ talent.name }}</h4>
        <span class="text-sm font-bold" *ngFor="let sk of talent.skills"
          >{{ sk }},
        </span>
        <div class="flex items-center opacity-50 mb-16">
          <app-icon [icon]="'location'" class="w-20 h-20 mr-4"></app-icon>
          <p class="text-sm">
            {{talent.location.name }} {{ talent.location.time }}
          </p>
        </div>
      </div>
    </div>

    <p class="flex items-center mb-16">
      {{ talent.description }}
    </p>

    <div class="flex justify-end">
      <button class="btn btn-stroke btn-sm">See more details</button>
    </div>
  </div>
  <div class="ratio-16-9 bg-gray-body-darken w-2/3"></div>
</div>
