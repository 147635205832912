import {Component, OnInit, Input} from "@angular/core";

@Component({
  selector: "app-job-details",
  templateUrl: "./job-details.component.html",
  styleUrls: ["./job-details.component.scss"],
  host: {
    class: "bg-white block rounded-8 p-16 relative",
  },
})
export class JobDetailsComponent implements OnInit {
  @Input() jobDetails;
  constructor() {}

  ngOnInit(): void {}
}
