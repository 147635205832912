import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { PersistanceService } from 'src/app/services/persistance-service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  host: {
    class: 'block h-full relative',
  },
})
export class RegisterComponent implements OnInit {
  formCompany: FormGroup;
  formTalent: FormGroup;
  userType = 'user';

  talentAcceptTerms = false;
  tallentTypePassword = 'password';
  tallentTypeRepeatPassword = 'password';
  companyAcceptTerms = false;

  companyTypePassword = 'password';
  companyTypeRepeatPassword = 'password';
  talentRegisterError = '';
  talentRegisterErrorObj = {
    email: '',
    password: '',
    confirmPassword: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    acceptTerms: null,
    initAcceptTerms: true,
  };
  companyRegisterError = '';
  companyRegisterErrorObj = {
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    acceptTerms: null,
    initAcceptTerms: true,
  };

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private persistanceService: PersistanceService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      let type = params['type'];
      if (type == undefined) {
        this.userType = 'user';
      } else {
        this.userType = type;
      }
    });
    this.formCompany = this.formBuilder.group({
      username: [, []],
      password: [, []],
      repeatPassword: [, []],
      name: [, []],
    });

    this.formTalent = this.formBuilder.group({
      username: [, []],
      password: [, []],
      repeatPassword: [, []],
      first_name: [, []],
      middle_name: [, []],
      last_name: [, []],
    });
  }

  switchTallent() {
    this.userType = 'user';
  }

  switchCompany() {
    this.userType = 'company';
  }

  acceptTerms() {
    this.talentRegisterErrorObj.initAcceptTerms = false;
    this.talentAcceptTerms = !this.talentAcceptTerms;
    if (this.talentAcceptTerms) {
      this.talentRegisterErrorObj.acceptTerms = true;
    } else {
      this.talentRegisterErrorObj.acceptTerms = false;
    }
  }

  acceptCompanyTerms() {
    this.companyRegisterErrorObj.initAcceptTerms = false;
    this.companyAcceptTerms = !this.companyAcceptTerms;
    if (this.companyAcceptTerms) {
      this.companyRegisterErrorObj.acceptTerms = true;
    } else {
      this.companyRegisterErrorObj.acceptTerms = false;
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateRegisterTalent() {
    var valid = true;

    this.talentRegisterErrorObj.initAcceptTerms = false;
    if (
      this.formTalent.value.username == null ||
      this.formTalent.value.username == ''
    ) {
      this.talentRegisterErrorObj.email = 'Email is required';
      valid = false;
    } else if (this.validateEmail(this.formTalent.value.username) == false) {
      this.talentRegisterErrorObj.email = 'Email is not valid';
      valid = false;
    } else {
      this.talentRegisterErrorObj.email = '';
    }

    if (
      this.formTalent.value.password == null ||
      this.formTalent.value.password == ''
    ) {
      this.talentRegisterErrorObj.password = 'Password is required';
      valid = false;
    } else if (this.formTalent.value.password.length < 6) {
      this.talentRegisterErrorObj.password = 'Password minimum length is 6';
      valid = false;
    } else {
      this.talentRegisterErrorObj.password = '';
    }

    if (
      this.formTalent.value.repeatPassword == null ||
      this.formTalent.value.repeatPassword == ''
    ) {
      this.talentRegisterErrorObj.confirmPassword =
        'Confrim Password is required';
      valid = false;
    } else if (
      this.formTalent.value.password != this.formTalent.value.repeatPassword
    ) {
      this.talentRegisterErrorObj.confirmPassword = 'Password does not match';
      valid = false;
    } else {
      this.talentRegisterErrorObj.confirmPassword = '';
    }

    if (
      this.formTalent.value.first_name == null ||
      this.formTalent.value.first_name == ''
    ) {
      this.talentRegisterErrorObj.first_name = 'first name is required';
      valid = false;
    } else if (this.formTalent.value.first_name.length < 2) {
      this.talentRegisterErrorObj.first_name = 'first name minimum length is 2';
      valid = false;
    } else {
      this.talentRegisterErrorObj.first_name = '';
    }

    if (
      this.formTalent.value.middle_name == null ||
      this.formTalent.value.middle_name == ''
    ) {
      this.talentRegisterErrorObj.middle_name = 'middle name is required';
      valid = false;
    } else if (this.formTalent.value.middle_name.length < 2) {
      this.talentRegisterErrorObj.middle_name =
        'middle name minimum length is 2';
      valid = false;
    } else {
      this.talentRegisterErrorObj.middle_name = '';
    }

    if (
      this.formTalent.value.last_name == null ||
      this.formTalent.value.last_name == ''
    ) {
      this.talentRegisterErrorObj.last_name = 'last name is required';
      valid = false;
    } else if (this.formTalent.value.last_name.length < 2) {
      this.talentRegisterErrorObj.last_name = 'last name minimum length is 2';
      valid = false;
    } else {
      this.talentRegisterErrorObj.last_name = '';
    }
    if (this.talentAcceptTerms) {
      this.talentRegisterErrorObj.acceptTerms = true;
    } else {
      this.talentRegisterErrorObj.acceptTerms = false;
      valid = false;
    }

    return valid;
  }

  registerTalent() {
    if (!this.validateRegisterTalent()) {
      return;
    }

    if (
      this.formTalent.value.password != this.formTalent.value.repeatPassword
    ) {
      this.talentRegisterError = "Password doen't match";
      return;
    }
    this.talentRegisterError = '';

    this.authService.registerTalent('talent', this.formTalent.value).subscribe(
      (res) => {
        this.persistanceService.set('token', res['token']);
        this.persistanceService.set('userType', 'user');
        this.router.navigateByUrl('/main');
      },
      (error) => {
        this.talentRegisterError = error.error.message;
      }
    );
  }

  validateRegisterCompany() {
    var valid = true;
    this.companyRegisterErrorObj.initAcceptTerms = false;
    if (
      this.formCompany.value.username == null ||
      this.formCompany.value.username == ''
    ) {
      this.companyRegisterErrorObj.email = 'Email is required';
      valid = false;
    } else if (this.validateEmail(this.formCompany.value.username) == false) {
      this.companyRegisterErrorObj.email = 'Email is not valid';
      valid = false;
    } else {
      this.companyRegisterErrorObj.email = '';
    }

    if (
      this.formCompany.value.password == null ||
      this.formCompany.value.password == ''
    ) {
      this.companyRegisterErrorObj.password = 'Password is required';
      valid = false;
    } else if (this.formCompany.value.password.length < 6) {
      this.companyRegisterErrorObj.password = 'Password minimum length is 6';
      valid = false;
    } else {
      this.companyRegisterErrorObj.password = '';
    }

    if (
      this.formCompany.value.repeatPassword == null ||
      this.formCompany.value.repeatPassword == ''
    ) {
      this.companyRegisterErrorObj.confirmPassword =
        'Confrim Password is required';
      valid = false;
    } else if (
      this.formCompany.value.password != this.formCompany.value.repeatPassword
    ) {
      this.companyRegisterErrorObj.confirmPassword = 'Password does not match';
      valid = false;
    } else {
      this.companyRegisterErrorObj.confirmPassword = '';
    }

    if (
      this.formCompany.value.name == null ||
      this.formCompany.value.name == ''
    ) {
      this.companyRegisterErrorObj.name = 'Name is required';
      valid = false;
    } else if (this.formCompany.value.name.length < 2) {
      this.companyRegisterErrorObj.name = 'Name minimum length is 2';
      valid = false;
    } else {
      this.companyRegisterErrorObj.name = '';
    }

    return valid;
  }
  registerCompany() {
    if (this.validateRegisterCompany() == false) {
      return;
    }
    if (
      this.formCompany.value.password != this.formCompany.value.repeatPassword
    ) {
      this.companyRegisterError = "Password doesn't match";
      return;
    }
    this.companyRegisterError = '';
    this.authService
      .registerCompany(
        'company',
        this.formCompany.value.username,
        this.formCompany.value.password,
        this.formCompany.value.name
      )
      .subscribe(
        (res) => {
          this.persistanceService.set('token', res['token']);
          this.persistanceService.set('userType', 'company');
          this.router.navigateByUrl('/main');
        },
        (error) => {
          this.companyRegisterError = error.error.message;
        }
      );
  }

  showTallentTypeRepeatPassword() {
    if (this.tallentTypeRepeatPassword == 'password') {
      this.tallentTypeRepeatPassword = 'text';
    } else if (this.tallentTypeRepeatPassword == 'text') {
      this.tallentTypeRepeatPassword = 'password';
    }
  }

  showTallentTypePassword() {
    if (this.tallentTypePassword == 'password') {
      this.tallentTypePassword = 'text';
    } else if (this.tallentTypePassword == 'text') {
      this.tallentTypePassword = 'password';
    }
  }

  showCompanyTypeRepeatPassword() {
    if (this.companyTypeRepeatPassword == 'password') {
      this.companyTypeRepeatPassword = 'text';
    } else if (this.companyTypeRepeatPassword == 'text') {
      this.companyTypeRepeatPassword = 'password';
    }
  }

  showCompanyTypePassword() {
    if (this.companyTypePassword == 'password') {
      this.companyTypePassword = 'text';
    } else if (this.companyTypePassword == 'text') {
      this.companyTypePassword = 'password';
    }
  }
}
