<div
  *ngIf="userType == 'user' && loaded"
  class="container mx-auto grid grid-cols-8 gap-24 py-24 min-h-full"
  (click)="closeAllDropDown()"
>
  <div class="col-span-2">
    <div class="bg-white rounded-8 py-32 mb-24">
      <h4 class="text-lg font-bold mb-24 text-center">Add your photo</h4>
      <div class="edit-user-image w-136 h-136 rounded-1/2 relative mx-auto">
        <app-icon
          [icon]="'camera'"
          class="w-48 h-48"
          *ngIf="'/public/user_images/' == profileData.image"
        ></app-icon>

        <div class="img-container">
          <img
            src="https://www.see2hire.com/{{ profileData.image }}"
            *ngIf="'/public/user_images/' != profileData.image"
            alt=""
          />
        </div>
        <button>
          <input
            type="file"
            #imageInput
            (change)="onImageInputChange($event.target.files)"
            accept="image/*"
          />
          <app-icon
            [icon]="'plus'"
            [fill]="'blue'"
            *ngIf="'/public/user_images/' == profileData.image"
            class="w-16 h-16"
          ></app-icon>

          <app-icon
            [icon]="'edit'"
            [fill]="'blue'"
            *ngIf="'/public/user_images/' != profileData.image"
            class="w-16 h-16"
          >
          </app-icon>
        </button>
      </div>
    </div>
    <div class="bg-white rounded-8 py-32 mb-24 px-16">
      <h4 class="text-lg font-bold mb-24 text-center">Add your video</h4>
      <div class="edit-user-image ratio-4-3 rounded-8 relative">
        <app-icon
          [icon]="'video'"
          class="w-48 h-48"
          *ngIf="'/public/user_videos' == profileData.video"
        ></app-icon>
        <video
          src="https://www.see2hire.com/{{ profileData.video }}"
          *ngIf="'/public/user_videos' != profileData.video"
          controls
          class="absolute top-0 left-0"
        ></video>
        <button (click)="recordVideoPanel()">
          <app-icon
            [icon]="'plus'"
            [fill]="'blue'"
            class="w-16 h-16"
            *ngIf="'/public/user_videos/' == profileData.video"
          ></app-icon>
          <app-icon
            [icon]="'edit'"
            [fill]="'blue'"
            class="w-16 h-16"
            *ngIf="'/public/user_videos/' != profileData.video"
          >
          </app-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-span-4 bg-white relative z-0 rounded-8 p-24 h-full">
    <h3 class="text-xl font-bold pb-16 border-b border-border">
      Profile Settings
    </h3>
    <div class="grid grid-cols-4 gap-24 h-full">
      <ul class="col-span-1 profile-menu">
        <li
          class="cursor-pointer py-8 font-semibold"
          (click)="changeTab('account')"
          [ngClass]="{ active: activeTab == 'account' }"
        >
          Account
        </li>
        <li
          class="cursor-pointer py-8 font-semibold"
          (click)="changeTab('personal')"
          [ngClass]="{ active: activeTab == 'personal' }"
        >
          Personal
        </li>
        <li
          class="cursor-pointer py-8 font-semibold"
          (click)="changeTab('carrier')"
          [ngClass]="{ active: activeTab == 'carrier' }"
        >
          Carrier
        </li>
        <li
          class="cursor-pointer py-8 font-semibold"
          (click)="changeTab('summary')"
          [ngClass]="{ active: activeTab == 'summary' }"
        >
          Summary
        </li>
        <li
          class="cursor-pointer py-8 font-semibold"
          (click)="changeTab('experience')"
          [ngClass]="{ active: activeTab == 'experience' }"
        >
          Experience
        </li>
        <li
          class="cursor-pointer py-8 font-semibold"
          (click)="changeTab('education')"
          [ngClass]="{ active: activeTab == 'education' }"
        >
          Education
        </li>
        <li
          class="cursor-pointer py-8 hover:blue-n"
          (click)="changeTab('skills')"
          [ngClass]="{ active: activeTab == 'skills' }"
        >
          Skills
        </li>
        <li
          class="cursor-pointer py-8 hover:blue-n"
          (click)="changeTab('languages')"
          [ngClass]="{ active: activeTab == 'languages' }"
        >
          Languages
        </li>
      </ul>
      <div
        class="col-span-3 overflow-y-auto overflow-x-hidden relative h-full flex"
      >
        <div class="w-full" *ngIf="activeTab == 'account'" [@slideInOut]>
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-16"
          >
            <h2 class="text-lg font-bold">Account Details</h2>
            <button (click)="editMode()" *ngIf="!isEditMode">
              <app-icon
                [icon]="'edit'"
                [fill]="'blue'"
                class="w-20 h-20"
              ></app-icon>
            </button>
          </div>
          <form
            [formGroup]="accountDetailsForm"
            class="grid grid-cols-2 gap-24"
          >
            <div class="form-group">
              <label>Email</label>
              <input
                class="px-16"
                type="email"
                [(ngModel)]="profileData.email"
                formControlName="email"
              />
            </div>
            <div class="form-group">
              <label>Phone</label>
              <input
                class="px-16"
                type="mobile"
                [(ngModel)]="profileData.phone"
                formControlName="phone"
              />
            </div>
            <div class="form-group">
              <label>Mobile</label>
              <input
                class="px-16"
                type="mobile"
                [(ngModel)]="profileData.mobile_num"
                formControlName="mobile"
              />
            </div>
          </form>
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-32"
          >
            <h2 class="text-lg font-bold">Password</h2>
            <button (click)="editMode()" *ngIf="!isEditMode">
              <app-icon
                [icon]="'edit'"
                [fill]="'blue'"
                class="w-20 h-20"
              ></app-icon>
            </button>
          </div>
          <form action="" class="grid grid-cols-2 mb-32 gap-24">
            <div class="form-group">
              <label>Password</label>
              <input
                class="px-16"
                type="password"
                placeholder="Password"
                [disabled]="!isEditMode"
              />
            </div>
            <div></div>
            <div class="form-group" *ngIf="isEditMode" [@fadeUp]>
              <label>New Password</label>
              <input
                class="px-16"
                type="password"
                placeholder="password"
                [disabled]="!isEditMode"
              />
            </div>
            <div class="form-group" *ngIf="isEditMode" [@fadeUp]>
              <label>Repeat password</label>
              <input
                class="px-16"
                type="password"
                placeholder="repeat password"
                [disabled]="!isEditMode"
              />
            </div>
          </form>
        </div>
        <div class="w-full" *ngIf="activeTab == 'personal'" [@slideInOut]>
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-16"
          >
            <h2 class="text-lg font-bold">Personal information</h2>
            <button (click)="editMode()" *ngIf="!isEditMode">
              <app-icon
                [icon]="'edit'"
                [fill]="'blue'"
                class="w-20 h-20"
              ></app-icon>
            </button>
          </div>
          <div class="grid grid-cols-2 mb-32 gap-24">
            <div class="form-group">
              <label>First name</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="profileData.first_name"
                [disabled]="!isEditMode"
              />
            </div>
            <div class="form-group">
              <label>Middle name</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="profileData.middle_name"
                [disabled]="!isEditMode"
              />
            </div>
            <div class="form-group">
              <label>Last name</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="profileData.last_name"
                [disabled]="!isEditMode"
              />
            </div>

            <div
              class="form-group select"
              [ngClass]="{ open: switchGender, disabled: !isEditMode }"
            >
              <label>Gender</label>

              <input
                class="px-16"
                type="text"
                readonly
                [(ngModel)]="profileData.gender"
                [disabled]="!isEditMode"
                (click)="openGender($event)"
              />
              <ul *ngIf="switchGender" [@fadeUp]>
                <li *ngFor="let gen of genders" (click)="selectGen(gen)">
                  {{ gen.gender }}
                </li>
              </ul>
            </div>
            <div
              class="form-group select"
              [ngClass]="{ open: switchCountry, disabled: !isEditMode }"
            >
              <label>Country</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="profileData.country.country"
                readonly
                [disabled]="!isEditMode"
                (click)="openCountry($event)"
              />
              <ul *ngIf="switchCountry" [@fadeUp]>
                <li
                  *ngFor="let country of countries"
                  (click)="selectCountry(country)"
                  class="country"
                >
                  {{ country.country }}
                </li>
              </ul>
            </div>
            <div
              class="form-group select"
              [ngClass]="{ open: switchState, disabled: !isEditMode }"
            >
              <label>State</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="profileData.state.state"
                readonly
                [disabled]="!isEditMode"
                (click)="openState($event)"
              />
              <p *ngIf="stateErr">Please select state</p>
              <ul *ngIf="switchState" [@fadeUp]>
                <li
                  *ngFor="let state of states"
                  (click)="selectState(state)"
                  class="state"
                >
                  {{ state.state }}
                </li>
              </ul>
            </div>
            <div
              class="form-group select"
              [ngClass]="{ open: switchCity, disabled: !isEditMode }"
            >
              <label>City</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="profileData.city.city"
                [disabled]="!isEditMode"
                readonly
                (click)="openCity($event)"
              />
              <p *ngIf="cityErr">Please select city</p>
              <ul *ngIf="switchCity" [@fadeUp]>
                <li
                  *ngFor="let city of cities"
                  (click)="selectCity(city)"
                  class="city"
                >
                  {{ city.city }}
                </li>
              </ul>
            </div>
            <div class="form-group col-span-2">
              <label>Street address</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="profileData.street_address"
                [disabled]="!isEditMode"
              />
            </div>
            <div class="form-group">
              <label>Marital Status</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="profileData.marital_status"
                [disabled]="!isEditMode"
              />
            </div>
          </div>
        </div>
        <div class="w-full" *ngIf="activeTab == 'carrier'" [@slideInOut]>
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-16"
          >
            <h2 class="text-lg font-bold">Carrier</h2>
            <button (click)="editMode()" *ngIf="!isEditMode">
              <app-icon
                [icon]="'edit'"
                [fill]="'blue'"
                class="w-20 h-20"
              ></app-icon>
            </button>
          </div>
          <div class="grid grid-cols-2 mb-32 gap-24">
            <div class="form-group">
              <label>Job Experience</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="profileData.job_experience"
                [disabled]="!isEditMode"
              />
            </div>
            <div class="form-group">
              <label>Career Level</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="profileData.career_level"
                [disabled]="!isEditMode"
              />
            </div>
            <div class="form-group">
              <label>Current Salary</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="profileData.current_salary"
                [disabled]="!isEditMode"
              />
            </div>
            <div class="form-group">
              <label>Expected Salary</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="profileData.expected_salary"
                [disabled]="!isEditMode"
              />
            </div>
          </div>
          <label>Available</label>
          <span *ngIf="profileData.is_immediate_available == 1">Available</span>
          <span *ngIf="profileData.is_immediate_available == 0">Hired</span>
        </div>
        <div class="w-full" *ngIf="activeTab == 'summary'" [@slideInOut]>
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-16"
          >
            <h2 class="text-lg font-bold">Summary</h2>
            <button (click)="editMode()" *ngIf="!isEditMode">
              <app-icon
                [icon]="'edit'"
                [fill]="'blue'"
                class="w-20 h-20"
              ></app-icon>
            </button>
          </div>
          <div class="form-group">
            <textarea
              name="about me"
              id=""
              cols="30"
              rows="5"
              [disabled]="!isEditMode"
              >{{ profileData.about_me }}</textarea
            >
          </div>
        </div>
        <div class="w-full" *ngIf="activeTab == 'experience'" [@slideInOut]>
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-16"
          >
            <h2 class="text-lg font-bold">Experience</h2>
            <button (click)="editMode()" *ngIf="!isEditMode">
              <app-icon
                [icon]="'edit'"
                [fill]="'blue'"
                class="w-20 h-20"
              ></app-icon>
            </button>
          </div>
          <p *ngFor="let exp of profileData.profileExperience">
            <span class="title">Title: {{ exp.title }}</span>
            <span class="company">Company: {{ exp.company }}</span>
            <span class="desc">Description: {{ exp.description }}</span>
            <span class="st_date"
              >Start: {{ exp.date_start | date: "dd/MM/yyyy" }}</span
            >
            <span class="end_date"
              >End: {{ exp.date_end | date: "dd/MM/yyyy" }}</span
            >

            <span class="city">City:{{ exp.city.city }}</span>
          </p>
        </div>
        <div class="w-full" *ngIf="activeTab == 'education'" [@slideInOut]>
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-16"
          >
            <h2 class="text-lg font-bold">Education</h2>
            <button (click)="editMode()" *ngIf="!isEditMode">
              <app-icon
                [icon]="'edit'"
                [fill]="'blue'"
                class="w-20 h-20"
              ></app-icon>
            </button>
          </div>
          <p *ngFor="let ed of profileData.profileEducation">
            <span class="title"> {{ ed.degree_title }}</span>
            <span class="result">{{ ed.degree_result }}</span>
          </p>
        </div>
        <div class="w-full" *ngIf="activeTab == 'skills'" [@slideInOut]>
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-16"
          >
            <h2 class="text-lg font-bold">Skills</h2>
            <button (click)="editMode()" *ngIf="!isEditMode">
              <app-icon
                [icon]="'edit'"
                [fill]="'blue'"
                class="w-20 h-20"
              ></app-icon>
            </button>
          </div>
          <div
            class="grid grid-cols-5 gap-16 mb-32 relative"
            *ngIf="isEditMode"
          >
            <div
              class="form-group select col-span-2"
              [ngClass]="{ open: openSkillDD, error: skillExist }"
            >
              <label>Select Skill</label>

              <input
                class="px-16"
                type="text"
                [(ngModel)]="selectedSkill"
                (click)="openSkills($event)"
                (keyup)="filterSkills()"
                placeholder="Choose Skill"
              />
              <ul *ngIf="openSkillDD" [@fadeUp]>
                <li *ngFor="let skill of skills" (click)="selectSkill(skill)">
                  {{ skill.job_skill }}
                </li>
              </ul>
            </div>
            <div
              class="form-group select col-span-2"
              [ngClass]="{
                open: openExpSkillDD,
                disabled: !isEditMode,
                error: skillExist
              }"
            >
              <label>Select Experience</label>

              <input
                class="px-16"
                type="text"
                readonly
                [(ngModel)]="selectedExpSkill"
                (click)="openSkillExps($event)"
                placeholder="Choose Experience"
              />
              <ul *ngIf="openExpSkillDD" [@fadeUp]>
                <li
                  *ngFor="let skillExp of skillExps"
                  (click)="selectSkillExps(skillExp)"
                >
                  {{ skillExp.job_experience }}
                </li>
              </ul>
            </div>
            <div class="col-span-1 flex items-end">
              <button
                class="btn btn-flat btn-w-auto btn-sm-p"
                (click)="addSkill()"
                [disabled]="selectedSkill == '' || selectedExpSkill == ''"
              >
                Add
              </button>
            </div>
            <p
              *ngIf="skillExist"
              class="absolute -bottom-20 left-0 text-xs text-error"
            >
              You've already into the skill list'
            </p>
          </div>

          <span class="pill" *ngFor="let sk of profileData.profileSkills"
            >{{ sk.job_skill }} (2 years)
            <button *ngIf="isEditMode">
              <app-icon
                [icon]="'close'"
                fill="'blue'"
                (click)="removeSkill(sk)"
              ></app-icon>
            </button>
          </span>
        </div>
        <div class="w-full" *ngIf="activeTab == 'languages'" [@slideInOut]>
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-16"
          >
            <h2 class="text-lg font-bold">Languages</h2>
            <button (click)="editMode()" *ngIf="!isEditMode">
              <app-icon
                [icon]="'edit'"
                [fill]="'blue'"
                class="w-20 h-20"
              ></app-icon>
            </button>
          </div>
          <div
            class="grid grid-cols-5 gap-16 mb-32 relative"
            *ngIf="isEditMode"
          >
            <div
              class="form-group select col-span-2"
              [ngClass]="{ open: switchGender }"
            >
              <label>Select Language</label>
              <input
                class="px-16"
                type="text"
                [(ngModel)]="selectedLang"
                (click)="openLanguages($event)"
                (keyup)="filterLang()"
                placeholder="Choose Language"
              />
              <ul *ngIf="openLanguagesDD" [@fadeUp]>
                <li
                  *ngFor="let lang of languages"
                  (click)="selectLanguage(lang)"
                >
                  {{ lang.lang }}
                </li>
              </ul>
            </div>
            <div
              class="form-group select col-span-2"
              [ngClass]="{ open: switchGender, disabled: !isEditMode }"
            >
              <label>Select Experience</label>
              <input
                class="px-16"
                type="text"
                readonly
                [(ngModel)]="selectedLangExp"
                (click)="openLanguagesExp($event)"
                placeholder="Choose Experience"
              />
              <ul *ngIf="openLanguagesExpDD" [@fadeUp]>
                <li
                  *ngFor="let langExp of languagesExp"
                  (click)="selectLangExps(langExp)"
                >
                  {{ langExp.language_level }}
                </li>
              </ul>
            </div>
            <div class="col-span-1 flex items-end">
              <button
                class="btn btn-flat btn-w-auto"
                (click)="addLang()"
                [disabled]="selectedLang == '' || selectedLangExp == ''"
              >
                Add
              </button>
            </div>
            <p
              *ngIf="langExist"
              class="absolute -bottom-20 left-0 text-xs text-error"
            >
              You've already added into language list'
            </p>
          </div>

          <span class="pill" *ngFor="let lg of profileData.languages">
            {{ lg.language.lang }} {{ lg.language_level.language_level }}
            <button *ngIf="isEditMode" (click)="removeLang(lg)">
              <app-icon [icon]="'close'" fill="'blue'"></app-icon>
            </button>
          </span>
        </div>
      </div>

      <div
        *ngIf="isEditMode"
        [@fadeUp]
        class="border-t border-border py-32 col-start-2 col-span-3"
      >
        <button class="btn btn-blue mr-24" (click)="updateAcc()">Update</button>
        <button class="btn btn-stroke" (click)="cancelEdit()">Cancel</button>
      </div>
    </div>
  </div>
  <div class="col-span-2 bg-white rounded-8 p-24">
    <div class="csv">
      <p>Cvs</p>
      <span *ngFor="let csv of profileData.profileCvs">{{ csv }}</span>
    </div>
  </div>
</div>

<div
  id="recordPanelWrapper"
  *ngIf="hasRecordVideoPanel"
  class="flex justify-center items-center w-full h-full"
>
  <app-talent-record-video
    (close)="closeRecordVideo()"
    (uploadVideo)="uploadRecordedVideo()"
  ></app-talent-record-video>
</div>

<div id="company" *ngIf="userType == 'company' && loaded">
  <app-company-profile [profile]="profileData"></app-company-profile>
</div>
