<div class="container mx-auto px-16 lg:px-0">
  <h2 class="text-2xl font-bold text-center mb-56">How it works</h2>
  <div
    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-{{
      data.length
    }} gap-40"
  >
    <div class="flex flex-col items-center" *ngFor="let item of data">
      <app-icon [icon]="item.icon" [fill]="'blue'" class="w-64 h-64"></app-icon>
      <h3 class="text-xl font-bold text-center mt-32 mb-8">
        {{ item.title }}
      </h3>
      <p class="text-center">
        {{ item.text }}
      </p>
    </div>
  </div>
</div>
