import { Component, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  @Input() show: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
