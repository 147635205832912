<app-logo class="w-112 block mx-auto" [show]="false"></app-logo>
<div class="container mt-56 mx-auto">
  <div class="grid grid-cols-3 mb-56">
    <div class="flex justify-center">
      <ul>
        <li class="font-bold text-base mb-16">Talents</li>
        <li class="text-sm">
          <a href="">Find Job</a>
        </li>
        <li class="text-sm">
          <a href="">Tips for landing a job</a>
        </li>
        <li class="text-sm">
          <a href="">Help & FAQ</a>
        </li>
        <li class="text-sm">
          <a href="">Help & FAQ</a>
        </li>
        <li class="text-sm">
          <a href="">Refer friends, get paid!</a>
        </li>
        <li class="text-sm">
          <a href="">Contact Us</a>
        </li>
      </ul>
    </div>
    <div class="flex justify-center">
      <ul>
        <li class="font-bold text-base mb-16">Talents</li>
        <li class="text-sm">
          <a href="">Find Job</a>
        </li>
        <li class="text-sm">
          <a href="">Tips for landing a job</a>
        </li>
        <li class="text-sm">
          <a href="">Help & FAQ</a>
        </li>
        <li class="text-sm">
          <a href="">Help & FAQ</a>
        </li>
        <li class="text-sm">
          <a href="">Refer friends, get paid!</a>
        </li>
        <li class="text-sm">
          <a href="">Contact Us</a>
        </li>
      </ul>
    </div>
    <div class="flex justify-center">
      <ul>
        <li class="font-bold text-base mb-16">Talents</li>
        <li class="text-sm">
          <a href="">Find Job</a>
        </li>
        <li class="text-sm">
          <a href="">Tips for landing a job</a>
        </li>
        <li class="text-sm">
          <a href="">Help & FAQ</a>
        </li>
        <li class="text-sm">
          <a href="">Help & FAQ</a>
        </li>
        <li class="text-sm">
          <a href="">Refer friends, get paid!</a>
        </li>
        <li class="text-sm">
          <a href="">Contact Us</a>
        </li>
      </ul>
    </div>
  </div>
  <p class="text-xs pb-16 text-center">
    Copyright © 2020 See2hire. All Rights Reserved.
  </p>
</div>
