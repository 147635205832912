import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-section',
  templateUrl: './blog-section.component.html',
  styleUrls: ['./blog-section.component.scss'],
  host: {
    class: 'py-56 block',
  },
})
export class BlogSectionComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }

}
