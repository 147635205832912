import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PersistanceService } from '../../services/persistance-service.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    class: 'flex flex-col relative bg-header h-headerh',
  },
})
export class HeaderComponent implements OnInit {
  @Input() lineOne;
  @Input() lineTwo;
  @Output() logginAction: EventEmitter<any> = new EventEmitter();
  constructor(
    private persistanceService: PersistanceService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  login() {
    this.persistanceService.set('token', 'loggedUser');
    this.persistanceService.set('userType', 'user');
    this.logginAction.emit();
    this.router.navigateByUrl('/main');
  }
}
