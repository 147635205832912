<div **ngIf="userType == 'user'">
  You should be logged in with company profile.
</div>
<div
  id="profile"
  *ngIf="loaded"
  class="container mx-auto grid grid-cols-12 gap-24 py-24"
>
  <div class="col-span-3">
    <div class="bg-white rounded-8 py-32 px-16">
      <app-avatar
        class="w-128 h-128 mx-auto mb-24"
        [avatarUrl]="profileData.image"
      ></app-avatar>

      <h3 class="text-center font-bold text-lg mb-8">{{ profileData.name }}</h3>

      <p class="text-sm text-center text-bold">
        {{ profileData.title }}
      </p>

      <p class="text-sm opacity-50 text-center mb-16">
        {{ profileData.country.country }}
        -
        {{ profileData.city.city }}
      </p>

      <p class="text-center mb-8 text-sm">
        <b>{{ profileData.mobile_num }}</b>
      </p>
      <p class="text-center mb-16 text-sm">
        <b>{{ profileData.email }}</b>
      </p>
      <div class="flex justify-center mt-32">
        <a href="" class="mx-16 block">
          <app-icon [icon]="'fb'" [fill]="'blue'" class="w-24 h-24"></app-icon>
        </a>
        <a href="" class="mx-16 block">
          <app-icon [icon]="'tw'" [fill]="'blue'" class="w-24 h-24"></app-icon>
        </a>
        <a href="" class="mx-16 block">
          <app-icon [icon]="'in'" [fill]="'blue'" class="w-24 h-24"></app-icon>
        </a>
      </div>
      <ul class="border-t border-gray-body-darken mt-32 pt-16">
        <li class="flex justify-between text-sm leading-10">
          <span class="font-bold">Is email verify:</span>
        </li>
        <li class="flex justify-between leading-10">
          <span class="font-bold">Immediate Available</span>
          <span *ngIf="profileData.is_immediate_available == 1">Yes</span>
          <span *ngIf="profileData.is_immediate_available == 0">No</span>
        </li>
        <li class="flex justify-between text-sm leading-10">
          <span class="font-bold">Age</span>
          <span>{{ profileData.age }}</span>
        </li>
        <li class="flex justify-between text-sm leading-10">
          <span class="font-bold">Gender</span>
          <span>{{ profileData.gender }}</span>
        </li>

        <li class="flex justify-between text-sm leading-10">
          <b>Marital Status</b>
          <span>{{ profileData.marital_status }}</span>
        </li>
        <li class="flex justify-between text-sm leading-10">
          <b>Experience</b>
          <span>{{ profileData.job_experience }}</span>
        </li>

        <li class="flex justify-between text-sm leading-10">
          <b>Career Level</b>
          <span>{{ profileData.career_level }}</span>
        </li>
        <li class="flex justify-between text-sm leading-10">
          <b>Current Salary</b>
          <span>${{ formatNumber(profileData.current_salary) }}</span>
        </li>
        <li class="flex justify-between text-sm leading-10">
          <b>Expected Salary</b>
          <span>${{ formatNumber(profileData.expected_salary) }}</span>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-span-6">
    <div class="bg-gray-body-darken rounded-8 ratio-16-9 mb-24 overflow-hidden">
      <video
        id="{{ profileData.video }}"
        class="w-full h-full absolute top-0 left-0"
        controls
      >
        <source
          src="http://see2hire.com/{{ profileData.video }}"
          type="video/mp4"
        />
      </video>
    </div>

    <div class="tabs">
      <!-- <nav class="tabs-header mt-32 mb-16">
        <button (click)="switchTab(1)" [ngClass]="{ active: currentTab === 1 }">
          Profile
        </button>
        <button (click)="switchTab(2)" [ngClass]="{ active: currentTab === 2 }">
          CV/Resume
        </button>
        <button (click)="switchTab(3)" [ngClass]="{ active: currentTab === 3 }">
          Portfolio
        </button>
      </nav> -->
      <div id="tab-content" class="bg-white rounded-8 p-16">
        <div id="tab1" *ngIf="currentTab == 1">
          <div class="bio">
            <h3 class="pb-8 text-xl border-b border-gray-body-darken mb-16">
              Bio
            </h3>
            <p>
              {{ profileData.about_me }}
            </p>
          </div>
          <div class="skills">
            <h3
              class="pb-8 text-xl border-b border-gray-body-darken mb-16 mt-24"
            >
              Skills
            </h3>
            <span
              *ngFor="let skill of profileData.profileSkills"
              class="rounded-24 py-8 px-16 border border-gray-body-darken text-sm mx-8"
              >{{ skill.job_skill }}</span
            >
          </div>
          <div class="education">
            <h3
              class="pb-8 text-xl border-b border-gray-body-darken mb-16 mt-24"
            >
              Education
            </h3>

            <span
              *ngFor="let edu of profileData.profileEducation"
              class="rounded-24 py-8 px-16 border border-gray-body-darken text-sm mx-8"
            >
              {{ edu.degree_title }}
            </span>
          </div>

          <div class="languages">
            <h3
              class="pb-8 text-xl border-b border-gray-body-darken mb-16 mt-24"
            >
              Languages
            </h3>

            <span
              *ngFor="let lang of profileData.languages"
              class="rounded-24 py-8 px-16 border border-gray-body-darken text-sm mx-8"
            >
              {{ lang.language.lang }}
            </span>
          </div>
        </div>
        <div id="tab2" *ngIf="currentTab == 2">
          <h3 class="pb-8 text-xl border-b border-gray-body-darken mb-16">
            CV/Resume
          </h3>

          <div>
            <a
              href="http://see2hire.com/cvs/{{ cvs.cv_file }}"
              *ngFor="let cvs of profileData.profileCvs"
              target="_blank"
              >CV {{ cvs.title }}</a
            >
          </div>
        </div>
        <div id="tab3" *ngIf="currentTab == 3">
          <h3 class="pb-8 text-xl border-b border-gray-body-darken mb-16">
            Portfolio
          </h3>

          <div *ngFor="let ex of profileData.profileExperience">
            {{ ex.title }} - {{ ex.company }}

            <p>Start: {{ ex.date_start }}</p>
            <p>End: {{ ex.date_end }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-span-3">
    <div class="bg-white rounded-8 p-16 mb-16">
      <h3 class="pb-8 text-xl border-b border-gray-body-darken">Website</h3>
      <a href="#">Personal Website</a>
    </div>

    <div class="bg-white rounded-8 p-16">
      <h3 class="pb-8 text-xl border-b border-gray-body-darken">Downloads</h3>

      <a
        href="http://see2hire.com/cvs/{{ cvs.cv_file }}"
        *ngFor="let cvs of profileData.profileCvs"
        target="_blank"
        >CV {{ cvs.title }}</a
      >
    </div>
  </div>
</div>
