<div class="flex items-center">
  <app-icon [icon]="'bulb'" class="w-40 h-40 mr-16 opacity-50"></app-icon>
  <h3 class="text-xl font-bold">Quick tips to get hired easy</h3>
</div>
<ul class="list-disc px-64 pt-16">
  <li class="pb-16">
    Letraset sheets containing Lorem Ipsum passages, and more recently with
    desktop publishing software like Aldus PageMaker including versions of Lorem
    Ipsum.
  </li>
  <li class="pb-16">
    Letraset sheets containing Lorem Ipsum passages, and more recently with
    desktop publishing software like Aldus PageMaker including versions of Lorem
    Ipsum.
  </li>
  <li class="pb-16">
    Letraset sheets containing Lorem Ipsum passages, and more recently with
    desktop publishing software like Aldus PageMaker including versions of Lorem
    Ipsum.
  </li>
</ul>
