import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
  host: {
    class:
      'w-530 flex flex-col justify-right items-center h-loginh md:h-full bg-white z-2 transition duration-300 absolute top-64 md:top-0 right-0 z-10',
  },
})
export class ForgotComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
