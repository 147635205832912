import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-letter',
  templateUrl: './news-letter.component.html',
  styleUrls: ['./news-letter.component.scss'],
  host: {
    class: 'block py-56 bg-gray-body-darken relative z-0',
  },
})
export class NewsLetterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
