<div
  class="rounded-8 overflow-hidden"
  [ngClass]="{ 'ratio-4-3 object-cover big': big === true, 'bg-white ': big === false }"
  [ngStyle]="bgImage()"
>
  <div
    class="ratio-4-3 overflow-hidden"
    *ngIf="big === false"
    [ngStyle]="smBgImage()"
  ></div>
  <p *ngIf="big === false" class="text-sm py-16 px-16">Aug 10 2020</p>
  <div
    class="border-b"
    [ngClass]="{ 'absolute bottom-64 left-24 right-24 border-white text-white z-10': big === true, 'px-16 pt-0 pb-16 border-gray-body-darken': big === false }"
  >
    <h3
      class="font-bold"
      [ngClass]="{ 'text-2xl w-2/3 mb-16': big === true, 'text-lg mb-16': big === false }"
    >
      Some nice long title for this blog article
    </h3>
    <p [ngClass]="{ 'w-2/3 mb-16': big === true, 'text-sm': big === false }">
      Two rows form the content of the article will come here and will finish
      with ellipcis
    </p>
  </div>
  <div
    class="flex justify-between items-center"
    [ngClass]="{ 'absolute bottom-16 left-24 right-24 text-white z-10': big === true, 'text-sm p-16': big === false }"
  >
    <div class="flex items-center">
      <div class="w-32 h-32 rounded-16 overflow-hidden object-cover relative">
        <img src="assets/img/face.jpg" alt="" class="w-full h-full" />
      </div>
      <p class="text-base font-bold ml-16">Tarra Stone</p>
    </div>
    <p class="text-sm" *ngIf="big===true">Aug 10 2020</p>
    <button
      class="hover:opacity-50 w-24 h-24 transition duration-300"
      *ngIf="big === false"
    >
      <app-icon [icon]="'share'" [fill]="'black'" class="h-24"></app-icon>
    </button>
  </div>
  <button
    class="absolute top-24 right-24 hover:opacity-75 w-24 h-24 transition duration-300 z-10"
    *ngIf="big == true"
  >
    <app-icon [icon]="'share'" [fill]="'white'" class="h-24"></app-icon>
  </button>
</div>
