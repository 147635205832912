import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { PersistanceService } from 'src/app/services/persistance-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: {
    class: 'block h-full relative',
  },
})
export class LoginComponent implements OnInit {
  hasLoader = false;

  form: FormGroup;
  userType = 'user';
  phoneTalent = '';
  phoneCompany = '';
  companyLoginError = '';
  talentLoginError = '';
  talentTypePassword = 'password';
  companyTypePassword = 'password';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private persistanceService: PersistanceService // private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: [, []],
      password: [, []],
    });
  }

  switchTallent() {
    this.userType = 'user';
  }

  switchCompany() {
    this.userType = 'company';
  }

  loginTalent() {
    this.hasLoader = true;
    this.authService
      .login('talent', this.form.value.username, this.form.value.password)
      .subscribe(
        (res) => {
          this.persistanceService.set('token', res['token']);
          this.persistanceService.set('imgHeader', res['data'].image);
          this.persistanceService.set('userType', 'user');
          this.talentLoginError = '';
          setTimeout(() => {
            this.router.navigateByUrl('/main');
            this.hasLoader = false;
          }, 1000);
        },
        (error) => {
          this.talentLoginError = error.error.message;
          this.hasLoader = false;
        }
      );
  }

  loginCompany() {
    this.hasLoader = true;
    this.authService
      .login('company', this.form.value.username, this.form.value.password)
      .subscribe(
        (res) => {
          this.persistanceService.set('token', res['token']);

          if (res['data'].logo == '/public/company_logos/')
            this.persistanceService.set('imgHeader', res['data'].logo);
          debugger;
          this.persistanceService.set('userType', 'company');
          this.companyLoginError = '';
          this.router.navigateByUrl('/main');
          this.hasLoader = false;
        },
        (error) => {
          this.companyLoginError = error.error.message;
          this.hasLoader = false;
        }
      );
  }

  showCompanyTypePassword() {
    if (this.companyTypePassword == 'password') {
      this.companyTypePassword = 'text';
    } else if (this.companyTypePassword == 'text') {
      this.companyTypePassword = 'password';
    }
  }

  showTalentTypePassword() {
    if (this.talentTypePassword == 'password') {
      this.talentTypePassword = 'text';
    } else if (this.talentTypePassword == 'text') {
      this.talentTypePassword = 'password';
    }
  }
}
