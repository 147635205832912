import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";

@Component({
  selector: "app-popular-search",
  templateUrl: "./popular-search.component.html",
  styleUrls: ["./popular-search.component.scss"],
  host: {
    class:
      "rounded-16 bg-gray-body px-8 flex items-center h-32 block mx-8 hover:bg-gray-body-darken cursor-pointer mb-16",
  },
})
export class PopularSearchComponent implements OnInit {
  @Input() search: any;
  @Output() select: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  selectSearch(search) {
    this.select.emit(search);
  }
}
