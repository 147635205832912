<div class="container mx-auto">
    <h2 class="text-center text-2xl font-bold">Our partners</h2>
    <div class="flex justify-between w-2/3 mx-auto mt-40">
        <div class="w-128 h-128 rounded-16 shadow-2xl bg-white flex justify-center items-center p-16 object-cover">
            <img src="assets/img/apple.png" alt="" class="object-cover">
        </div>
        <div class="w-128 h-128 rounded-16 shadow-2xl bg-white flex justify-center items-center p-16 object-cover">
            <img src="assets/img/apple.png" alt="" class="object-cover">
        </div>
        <div class="w-128 h-128 rounded-16 shadow-2xl bg-white flex justify-center items-center p-16 object-cover">
            <img src="assets/img/apple.png" alt="" class="object-cover">
        </div>
        <div class="w-128 h-128 rounded-16 shadow-2xl bg-white flex justify-center items-center p-16 object-cover">
            <img src="assets/img/apple.png" alt="" class="object-cover">
        </div>
        <div class="w-128 h-128 rounded-16 shadow-2xl bg-white flex justify-center items-center p-16 object-cover">
            <img src="assets/img/apple.png" alt="" class="object-cover">
        </div>
    </div>
</div>