<a href="" class="flex flex-col justify-center items-center">
    <svg viewBox="0 0 171 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_di)">
        <path d="M20.5304 70.4534H17.7704V69.7353H24.1206V70.4534H21.3606V77.589H20.5304V70.4534Z" fill="url(#paint0_radial)" [ngClass]="{ 'hidden': show == false }"/>
        <path d="M33.456 75.491H29.0804L28.1379 77.589H27.274L30.8643 69.7353H31.6833L35.2735 77.589H34.3984L33.456 75.491ZM33.153 74.8178L31.2682 70.5992L29.3833 74.8178H33.153Z" fill="url(#paint1_radial)" [ngClass]="{ 'hidden': show == false }"/>
        <path d="M40.0508 69.7353H40.881V76.871H45.2791V77.589H40.0508V69.7353Z" fill="url(#paint2_radial)" [ngClass]="{ 'hidden': show == false }"/>
        <path d="M55.6893 76.871V77.589H50.1356V69.7353H55.521V70.4534H50.9659V73.2471H55.0273V73.9539H50.9659V76.871H55.6893Z" fill="url(#paint3_radial)" [ngClass]="{ 'hidden': show == false }"/>
        <path d="M67.6823 69.7353V77.589H66.9979L61.9491 71.2163V77.589H61.1189V69.7353H61.8033L66.8633 76.108V69.7353H67.6823Z" fill="url(#paint4_radial)" [ngClass]="{ 'hidden': show == false }"/>
        <path d="M75.2496 70.4534H72.4896V69.7353H78.8399V70.4534H76.0799V77.589H75.2496V70.4534Z" fill="url(#paint5_radial)" [ngClass]="{ 'hidden': show == false }"/>
        <path d="M92.224 77.6563C91.6406 77.6563 91.0796 77.5628 90.5411 77.3758C90.01 77.1888 89.5987 76.942 89.307 76.6353L89.6323 75.9958C89.9166 76.2801 90.2943 76.5119 90.7655 76.6914C91.2367 76.8635 91.7229 76.9495 92.224 76.9495C92.9271 76.9495 93.4544 76.8223 93.806 76.568C94.1575 76.3062 94.3333 75.9697 94.3333 75.5583C94.3333 75.2441 94.2361 74.9936 94.0416 74.8066C93.8546 74.6196 93.6227 74.4775 93.346 74.3802C93.0692 74.2755 92.684 74.1633 92.1904 74.0436C91.5995 73.894 91.1283 73.7519 90.7767 73.6173C90.4252 73.4752 90.1222 73.262 89.8679 72.9778C89.6211 72.6936 89.4977 72.3084 89.4977 71.8222C89.4977 71.4258 89.6024 71.0667 89.8118 70.7451C90.0213 70.416 90.3429 70.1542 90.7767 69.9597C91.2105 69.7653 91.7491 69.668 92.3923 69.668C92.8411 69.668 93.2787 69.7316 93.705 69.8588C94.1388 69.9784 94.5128 70.1467 94.827 70.3636L94.5465 71.0256C94.2174 70.8087 93.8658 70.6479 93.4918 70.5432C93.1179 70.431 92.7514 70.3749 92.3923 70.3749C91.7042 70.3749 91.1844 70.5095 90.8328 70.7788C90.4887 71.0405 90.3167 71.3809 90.3167 71.7997C90.3167 72.1139 90.4102 72.3682 90.5972 72.5627C90.7917 72.7497 91.031 72.8955 91.3153 73.0002C91.607 73.0975 91.9959 73.2059 92.4821 73.3256C93.058 73.4677 93.5218 73.6098 93.8733 73.7519C94.2323 73.8866 94.5353 74.096 94.7821 74.3802C95.0289 74.657 95.1523 75.0347 95.1523 75.5134C95.1523 75.9098 95.0439 76.2726 94.827 76.6017C94.6175 76.9233 94.2922 77.1814 93.8509 77.3758C93.4096 77.5628 92.8673 77.6563 92.224 77.6563Z" fill="url(#paint6_radial)" [ngClass]="{ 'hidden': show == false }"/>
        <path d="M105.993 76.871V77.589H100.439V69.7353H105.825V70.4534H101.27V73.2471H105.331V73.9539H101.27V76.871H105.993Z" fill="url(#paint7_radial)" [ngClass]="{ 'hidden': show == false }" [ngClass]="{ 'hidden': show == false }"/>
        <path d="M116.348 75.491H111.972L111.03 77.589H110.166L113.756 69.7353H114.575L118.166 77.589H117.29L116.348 75.491ZM116.045 74.8178L114.16 70.5992L112.275 74.8178H116.045Z" fill="url(#paint8_radial)" [ngClass]="{ 'hidden': show == false }" [ngClass]="{ 'hidden': show == false }"/>
        <path d="M128.306 77.589L126.511 75.0646C126.309 75.0871 126.099 75.0983 125.882 75.0983H123.773V77.589H122.943V69.7353H125.882C126.885 69.7353 127.67 69.9747 128.238 70.4534C128.807 70.9321 129.091 71.5903 129.091 72.428C129.091 73.0414 128.934 73.5612 128.62 73.9875C128.313 74.4064 127.872 74.7093 127.296 74.8963L129.214 77.589H128.306ZM125.86 74.3914C126.638 74.3914 127.232 74.2194 127.644 73.8753C128.055 73.5313 128.261 73.0488 128.261 72.428C128.261 71.7923 128.055 71.3061 127.644 70.9695C127.232 70.6254 126.638 70.4534 125.86 70.4534H123.773V74.3914H125.86Z" fill="url(#paint9_radial)" [ngClass]="{ 'hidden': show == false }"/>
        <path d="M137.939 77.6563C137.162 77.6563 136.458 77.4843 135.83 77.1402C135.209 76.7962 134.719 76.3212 134.36 75.7153C134.009 75.102 133.833 74.4176 133.833 73.6622C133.833 72.9067 134.009 72.2261 134.36 71.6202C134.719 71.0069 135.213 70.5282 135.841 70.1841C136.47 69.8401 137.173 69.668 137.951 69.668C138.534 69.668 139.073 69.7653 139.566 69.9597C140.06 70.1542 140.479 70.4384 140.823 70.8124L140.296 71.3397C139.682 70.7189 138.908 70.4085 137.973 70.4085C137.352 70.4085 136.788 70.5506 136.279 70.8349C135.77 71.1191 135.37 71.508 135.078 72.0017C134.794 72.4953 134.652 73.0488 134.652 73.6622C134.652 74.2755 134.794 74.829 135.078 75.3227C135.37 75.8163 135.77 76.2053 136.279 76.4895C136.788 76.7737 137.352 76.9158 137.973 76.9158C138.916 76.9158 139.69 76.6017 140.296 75.9734L140.823 76.5007C140.479 76.8747 140.056 77.1627 139.555 77.3646C139.061 77.5591 138.523 77.6563 137.939 77.6563Z" fill="url(#paint10_radial)" [ngClass]="{ 'hidden': show == false }"/>
        <path d="M152.634 69.7353V77.589H151.815V73.9651H146.901V77.589H146.071V69.7353H146.901V73.2358H151.815V69.7353H152.634Z" fill="url(#paint11_radial)" [ngClass]="{ 'hidden': show == false }"/>
        <path d="M16.3616 35.3226C16.3616 34.4387 16.0496 33.7628 15.4257 33.2948C14.8018 32.8165 13.6787 32.3173 12.0565 31.7974C10.4343 31.2671 9.15011 30.7471 8.20383 30.2376C5.62495 28.8442 4.33551 26.9672 4.33551 24.6067C4.33551 23.3797 4.67867 22.2878 5.36498 21.3311C6.0617 20.364 7.05477 19.6101 8.34421 19.0694C9.64405 18.5287 11.0999 18.2583 12.7117 18.2583C14.3339 18.2583 15.7793 18.5547 17.0479 19.1474C18.3166 19.7297 19.2992 20.5564 19.996 21.6275C20.7031 22.6985 21.0566 23.9152 21.0566 25.2774H16.3772C16.3772 24.2376 16.0496 23.4317 15.3945 22.8597C14.7394 22.2774 13.8191 21.9862 12.6337 21.9862C11.4898 21.9862 10.6007 22.2306 9.96641 22.7193C9.33209 23.1977 9.01493 23.832 9.01493 24.6223C9.01493 25.3606 9.38408 25.9793 10.1224 26.4785C10.8711 26.9776 11.9682 27.4456 13.4136 27.8823C16.0756 28.683 18.015 29.6761 19.2317 30.8615C20.4483 32.047 21.0566 33.5236 21.0566 35.2914C21.0566 37.2567 20.3131 38.8009 18.8261 39.924C17.3391 41.0367 15.3373 41.593 12.8209 41.593C11.0739 41.593 9.48287 41.2758 8.04785 40.6415C6.61283 39.9968 5.89871 39.1067 4.75666 38.0054C3.94935 37.1574 2.97468 35.6954 2 33.746L5.89871 31.7974C6.87338 33.746 9.82603 37.8963 12.8209 37.8963C13.9335 37.8963 14.8018 37.6727 15.4257 37.2255C16.0496 36.768 16.3616 36.1337 16.3616 35.3226Z" fill="url(#paint12_radial)"/>
        <path d="M38.9899 32.2126H30.3798V37.6251H40.5498V41.8366H24.9049V19.1258H40.581V23.3529H30.3798V28.1415H38.9899V32.2126Z" fill="url(#paint13_radial)"/>
        <path d="M58.4835 32.2126H49.8734V37.6251H60.0433V41.8366H44.3984V19.1258H60.0745V23.3529H49.8734V28.1415H58.4835V32.2126Z" fill="url(#paint14_radial)"/>
        <path d="M118.45 42.078H113.007V32.5943H104.942V42.078H99.4675V19.3672H104.942V28.3829H113.007V19.3672H118.45V42.078Z" fill="url(#paint15_radial)"/>
        <path d="M127.762 42.078H122.303V19.3672H127.762V42.078Z" fill="url(#paint16_radial)"/>
        <path d="M140.007 34.0606H137.043V42.078H131.568V19.3672H140.506C143.199 19.3672 145.305 19.9651 146.823 21.161C148.341 22.3568 149.101 24.0466 149.101 26.2303C149.101 27.8109 148.778 29.1212 148.133 30.161C147.499 31.2009 146.501 32.0432 145.139 32.6879L149.88 41.844V42.078H144.016L140.007 34.0606ZM137.043 29.8335H140.506C141.546 29.8335 142.326 29.5631 142.846 29.0224C143.376 28.4713 143.641 27.7069 143.641 26.7295C143.641 25.752 143.376 24.9877 142.846 24.4366C142.315 23.875 141.535 23.5943 140.506 23.5943H137.043V29.8335Z" fill="url(#paint17_radial)"/>
        <path d="M166.492 32.454H157.882V37.8665H168.052V42.078H152.407V19.3672H168.083V23.5943H157.882V28.3829H166.492V32.454Z" fill="url(#paint18_radial)"/>
        <path d="M87.7719 43.1506H68.8374V38.9405L77.5592 29.525C79.7086 27.0116 80.7833 25.015 80.7833 23.535C80.7833 22.3358 80.5286 21.4235 80.0192 20.7984C79.5098 20.1733 78.7705 19.8607 77.8015 19.8607C76.8448 19.8607 76.0683 20.2817 75.4719 21.1237C74.8756 21.953 74.5774 22.9928 74.5774 24.2431H68.2783C68.2783 22.5335 68.6945 20.9579 69.527 19.5162C70.3594 18.0618 71.5148 16.9263 72.9933 16.1098C74.4718 15.2933 76.1242 14.885 77.9505 14.885C80.8827 14.885 83.1376 15.5803 84.7155 16.971C86.3058 18.3616 87.101 20.3582 87.101 22.9609C87.101 24.0581 86.9022 25.1298 86.5046 26.176C86.107 27.2094 85.4858 28.3002 84.641 29.4484C83.8085 30.5839 82.4605 32.1085 80.5969 34.0222L77.0933 38.175H87.7719V43.1506Z" fill="url(#paint19_radial)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M74.913 48.7537C85.5504 50.7054 95.7558 43.6642 97.7074 33.0268C99.6591 22.3894 92.6179 12.184 81.9805 10.2324C71.3431 8.28072 61.1377 15.3219 59.1861 25.9593C57.2344 36.5967 64.2756 46.8021 74.913 48.7537ZM74.0296 53.5689C81.238 54.8914 88.2878 52.9103 93.6211 48.7002L98.0133 52.8302L97.4053 53.4768L109.01 64.3888L114.921 58.1024L103.316 47.1904L102.708 47.8372L98.3553 43.744C100.392 40.9023 101.85 37.5772 102.523 33.9102C104.962 20.6135 96.1607 7.85673 82.8639 5.41719C69.5672 2.97764 56.8104 11.7791 54.3709 25.0759C51.9314 38.3726 60.7328 51.1293 74.0296 53.5689Z" fill="url(#paint20_radial)"/>
        <g style="mix-blend-mode:overlay" opacity="0.36">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M74.913 48.7537C85.5504 50.7054 95.7558 43.6642 97.7074 33.0268C99.6591 22.3894 92.6179 12.184 81.9805 10.2324C71.3431 8.28072 61.1377 15.3219 59.1861 25.9593C57.2344 36.5967 64.2756 46.8021 74.913 48.7537ZM74.0296 53.5689C81.238 54.8914 88.2878 52.9103 93.6211 48.7002L98.0133 52.8302L97.4053 53.4768L109.01 64.3888L114.921 58.1024L103.316 47.1904L102.708 47.8372L98.3553 43.744C100.392 40.9023 101.85 37.5772 102.523 33.9102C104.962 20.6135 96.1607 7.85673 82.8639 5.41719C69.5672 2.97764 56.8104 11.7791 54.3709 25.0759C51.9314 38.3726 60.7328 51.1293 74.0296 53.5689Z" fill="url(#paint21_radial)"/>
        </g>
        </g>
        <defs>
        <filter id="filter0_di" x="0" y="0" width="170.083" height="80.6563" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.7 0"/>
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow"/>
        </filter>
        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.6332 67.8345) rotate(7.62924) scale(151.848 88.5103)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.6332 67.8345) rotate(7.62924) scale(151.848 88.5103)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.6332 67.8345) rotate(7.62924) scale(151.848 88.5103)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint3_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.6332 67.8345) rotate(7.62924) scale(151.848 88.5103)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint4_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.6332 67.8345) rotate(7.62924) scale(151.848 88.5103)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint5_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.6332 67.8345) rotate(7.62924) scale(151.848 88.5103)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint6_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.6332 67.8345) rotate(7.62924) scale(151.848 88.5103)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint7_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.6332 67.8345) rotate(7.62924) scale(151.848 88.5103)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint8_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.6332 67.8345) rotate(7.62924) scale(151.848 88.5103)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint9_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.6332 67.8345) rotate(7.62924) scale(151.848 88.5103)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint10_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.6332 67.8345) rotate(7.62924) scale(151.848 88.5103)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint11_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.6332 67.8345) rotate(7.62924) scale(151.848 88.5103)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint12_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.074 21.4833) rotate(28.5387) scale(60.0458 111.634)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint13_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.074 21.4833) rotate(28.5387) scale(60.0458 111.634)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint14_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.074 21.4833) rotate(28.5387) scale(60.0458 111.634)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint15_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(136.182 22.4735) rotate(23.9099) scale(68.1754 111.895)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint16_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(136.182 22.4735) rotate(23.9099) scale(68.1754 111.895)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint17_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(136.182 22.4735) rotate(23.9099) scale(68.1754 111.895)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint18_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(136.182 22.4735) rotate(23.9099) scale(68.1754 111.895)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint19_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(78.7088 18.7511) rotate(62.7575) scale(38.6805 69.7333)">
        <stop stop-color="#F7F9F8"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint20_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.9532 14.4946) rotate(57.847) scale(88.002 194.198)">
        <stop stop-color="#0E1D35"/>
        <stop offset="1" stop-color="#424A4F"/>
        </radialGradient>
        <radialGradient id="paint21_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.9488 12.2088) rotate(61.1786) scale(116.254 256.544)">
        <stop stop-color="#F9F9F9"/>
        <stop offset="1" stop-color="#41AFD6"/>
        </radialGradient>
        </defs>
        </svg>
        
</a>

