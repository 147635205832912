import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(type, username, password) {
    const url = environment.apiUrl + 'web/login';
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    var user = {
      email: username,
      password: password,
      type: type,
    };

    return this.http.post(url, user, headers);
  }

  registerCompany(type, username, password, name) {
    const url = environment.apiUrl + 'web/register';
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    var user = {
      email: username,
      password: password,
      name: name,
      type: type,
    };

    return this.http.post(url, user, headers);
  }

  registerTalent(type, form) {
    const url = environment.apiUrl + 'web/register';
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    var user = {
      email: form.username,
      password: form.password,
      first_name: form.first_name,
      middle_name: form.middle_name,
      last_name: form.last_name,

      type: type,
    };

    return this.http.post(url, user, headers);
  }

  getCompanyProfile(token) {
    const url = environment.apiUrl + 'company/profile/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get(url, headers);
  }

  getTalentProfile(token) {
    const url = environment.apiUrl + 'talent/profile/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get(url, headers);
  }

  getTalentPublicProfile(token, userId) {
    const url = environment.apiUrl + 'public/talent/' + userId + '/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get(url, headers);
  }

  updateProfileVideo(token, video) {
    const url = environment.apiUrl + 'talent/update/video/' + token;

    const headers = {
      headers: new HttpHeaders({
        'content-type': 'multipart/form-data',
      }),
    };

    var formData = new FormData();
    formData.append('video', video);

    return this.http.post(url, formData, {
      observe: 'events',
      reportProgress: true,
    });
  }

  updateProfileImage(token, image) {
    const url = environment.apiUrl + 'talent/update/picture/' + token;

    const headers = {
      headers: new HttpHeaders({
        'content-type': 'multipart/form-data',
      }),
    };

    var formData = new FormData();
    formData.append('image', image);

    return this.http.post(url, formData, {
      observe: 'events',
      reportProgress: true,
    });
  }

  getProfile(token) {
    const url = environment.apiUrl + 'talent/profile/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, headers);
  }

  updateImage64(token, image) {
    const url = environment.apiUrl + 'update/picture64/' + token;
    const headers = {
      headers: new HttpHeaders({
        'content-type': 'multipart/form-data',
      }),
    };

    var data = {
      image: image,
    };

    return this.http.post(url, data, headers);
  }

  getGenders(token) {
    const url = environment.apiUrl + 'talent/gender/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, headers);
  }

  getCountry(token) {
    const url = environment.apiUrl + 'talent/country/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, headers);
  }

  getStates(token, countryId) {
    const url = environment.apiUrl + 'talent/state/' + countryId + '/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, headers);
  }

  getCities(token, stateId) {
    const url = environment.apiUrl + 'talent/city/' + stateId + '/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, headers);
  }

  updateProfile(token, profile) {
    const url = environment.apiUrl + 'talent/profile/' + token;
    const headers = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
      }),
    };

    var data = {
      email: profile.email,
      mobile_num: profile.mobile_num,
      phone: profile.phone,
      first_name: profile.first_name,
      middle_name: profile.middle_name,
      last_name: profile.last_name,
      gender_id: profile.gender_id,
      country_id: profile.country_id,
      state_id: profile.state_id,
      city_id: profile.city_id,

      job_experience: profile.job_experience,
      career_level: profile.career_level,
      current_salary: profile.current_salary,
      expected_salary: profile.expected_salary,
      about_me: profile.about_me,
      profileSkills: profile.profileSkills,
    };

    return this.http.post(url, data, headers);
  }

  getSkills(token) {
    const url = environment.apiUrl + 'talent/get/skills/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, headers);
  }

  getSkillsExpirience(token) {
    const url = environment.apiUrl + 'talent/get/skillExperience/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, headers);
  }

  getLanguages(token) {
    const url = environment.apiUrl + 'talent/get/languages/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, headers);
  }

  getLanguageExp(token) {
    const url = environment.apiUrl + 'talent/get/languageLevel/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(url, headers);
  }

  addLanguages(token, language_id, language_level_id) {
    const url = environment.apiUrl + 'talent/language/new/' + token;
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    const data = {
      language_id: language_id,
      language_level_id: language_level_id,
    };
    return this.http.post(url, data, headers);
  }

  deleteLanguages(token, id) {
    const url =
      environment.apiUrl + 'talent/language/delete/' + id + '/' + token;
    const headers = {
      headers: new HttpHeaders({
        'content-type': 'multipart/form-data',
      }),
    };

    return this.http.delete(url, headers);
  }
}
