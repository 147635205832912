import {Component, OnInit} from "@angular/core";

@Component({
  selector: "app-quick-tips",
  templateUrl: "./quick-tips.component.html",
  styleUrls: ["./quick-tips.component.scss"],
  host: {
    class: "block bg-white rounded-8 p-16",
  },
})
export class QuickTipsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
