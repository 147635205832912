import {Component, OnInit, Input} from "@angular/core";

@Component({
  selector: "app-talent-profile",
  templateUrl: "./talent-profile.component.html",
  styleUrls: ["./talent-profile.component.scss"],
  host: {
    class: "block bg-white rounded-8 mb-16 overflow-hidden",
  },
})
export class TalentProfileComponent implements OnInit {
  @Input() talent: any;
  constructor() {}

  ngOnInit(): void {}

  openMore(event) {
    event.stopImmediatePropagation();
    if (this.talent.more == null) {
      this.talent.more = true;
    } else {
      this.talent.more = !this.talent.more;
    }
  }
}
