import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { PersistanceService } from 'src/app/services/persistance-service.service';

@Component({
  selector: 'app-tallent-content',
  templateUrl: './tallent-content.component.html',
  styleUrls: ['./tallent-content.component.scss'],
})
export class TallentContentComponent implements OnInit {
  form: FormGroup;
  token = '';
  jobSearch = '';
  jobLocation = '';

  search = {
    jobValue: 'default',
    jobLocation: '',
  };

  hasHelper = true;
  howWorks = [
    {
      icon: 'account',
      title: 'Create an Account',
      text:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
      icon: 'resume',
      title: 'Upload Your Resume',
      text:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
      icon: 'video-upload',
      title: 'Upload Your Video',
      text:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
      icon: 'oportunities',
      title: 'Find oportunities',
      text:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
  ];
  videoCeo = {
    src: 'https://www.youtube.com/embed/NKYcBAF1jyY',
    title: 'Get some tips from our SEO',
    content:
      'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th',
  };

  hasSearch = false;

  popularSearches = [
    {
      type: 'location',
      text: 'Remote',
    },
    {
      type: 'title',
      text: 'Store associate',
    },
    {
      type: 'title',
      text: 'Healthcare administration',
    },
    {
      type: 'title',
      text: 'Amazon',
    },
    {
      type: 'title',
      text: 'FedEx',
    },
  ];

  jobs = [
    {
      company: {
        name: 'BizTender',
        img:
          'https://i.pinimg.com/236x/1a/8a/8f/1a8a8f613d753aac26311b4115d09499.jpg',
        sizeInfo: '1-10 employees ',
      },
      shortDescription:
        'Angular 2/4/5 Frontend Engineer to transform healthcare and improve health outcomes',
      location: 'London',
      postedInfo: 'Posted 14 hours ago',
      description: {
        shortInfo:
          'The team is growing! BizTender is always on the look for talented, goal- oriented, team players.Qualities should include self- discipline, persistence, resilience, and grit.Along with being Tech Savvy and a quick learner.This is an entry - level position.',
        responsibilities: `Build out design comps into fully functioning web interactions that are responsive, optimized, semantic, and accessible. 
        Collaborate with the team and client on wireframes, interactive prototypes, and production- ready web experiences.
        Evaluate design deliverables for build feasibility and provide constructive feedback to internal, client- side, and third - party designers, user experience designers, and project managers.
        Perform cross - browser testing and remediation for front - end performance and visual quality assurance.
        Partner with our designers, lead engineers, and PM to move a project through development and launch.
        Build interactive components with modern JavaScript.
        Would be helping with client maintenance and support.`,
        requirements: `Strong writing & communication skills.
        Strong understanding of HTML, CSS, basic WCAG accessibility, and Basics understanding of responsive design.
        Ability to write modern and accessible JavaScript.
        21+ years of age preferred.
        Compensation:
        Work remotely around your schedule.
        10-20 a week to start, would grow to about 30.
        Entry-level pay with the first raise within 90 days.`,
        experience: `We know that this is an entry-level position. Nothing on the list below is mandatory, but is defiantly nice to haves and would be a plus in your favor.

        Understanding of Metrc(Cannabis Tracking)
        WordPress experience
        Elementor and Gutenberg
        Object-Oriented PHP
        Experience using Git.
        Experience with Grunt, Gulp, or Docker
        Unit Testing
        ES6+ JavaScript, possibly with React/Angular experience.
        Min 1 Year in related fields preferred
        Location:
        Remote, USA preferred for events and installations.
        Physical Activities:
        Physical aptitude and health are necessary to perform manual labor tasks. May include: extended periods of sitting, lengthy typing`,
      },
      open: false,
    },

    {
      company: {
        name: 'Amazon',
        img:
          'https://i.pinimg.com/236x/49/bd/e6/49bde607d5307858d4ef739da246a763.jpg',
        sizeInfo: '50-100 employees ',
      },
      shortDescription: 'Front-end developer',
      location: 'Remote',
      postedInfo: 'Posted 14 hours ago',
      description: {
        shortInfo:
          'The team is growing! BizTender is always on the look for talented, goal- oriented, team players.Qualities should include self- discipline, persistence, resilience, and grit.Along with being Tech Savvy and a quick learner.This is an entry - level position.',
        responsibilities: `Build out design comps into fully functioning web interactions that are responsive, optimized, semantic, and accessible. 
        Collaborate with the team and client on wireframes, interactive prototypes, and production- ready web experiences.
        Evaluate design deliverables for build feasibility and provide constructive feedback to internal, client- side, and third - party designers, user experience designers, and project managers.
        Perform cross - browser testing and remediation for front - end performance and visual quality assurance.
        Partner with our designers, lead engineers, and PM to move a project through development and launch.
        Build interactive components with modern JavaScript.
        Would be helping with client maintenance and support.`,
        requirements: `Strong writing & communication skills.
        Strong understanding of HTML, CSS, basic WCAG accessibility, and Basics understanding of responsive design.
        Ability to write modern and accessible JavaScript.
        21+ years of age preferred.
        Compensation:
        Work remotely around your schedule.
        10-20 a week to start, would grow to about 30.
        Entry-level pay with the first raise within 90 days.`,
        experience: `We know that this is an entry-level position. Nothing on the list below is mandatory, but is defiantly nice to haves and would be a plus in your favor.

        Understanding of Metrc(Cannabis Tracking)
        WordPress experience
        Elementor and Gutenberg
        Object-Oriented PHP
        Experience using Git.
        Experience with Grunt, Gulp, or Docker
        Unit Testing
        ES6+ JavaScript, possibly with React/Angular experience.
        Min 1 Year in related fields preferred
        Location:
        Remote, USA preferred for events and installations.
        Physical Activities:
        Physical aptitude and health are necessary to perform manual labor tasks. May include: extended periods of sitting, lengthy typing`,
      },
      open: false,
    },
  ];

  selectedJob: null;

  constructor(
    private formBuilder: FormBuilder,
    private persistanceService: PersistanceService
  ) {}

  ngOnInit() {
    this.persistanceService.set('headerLineOne', 'Find company and job');
    this.persistanceService.set('headerLineTwo', 'fit to you');
    this.token = this.persistanceService.get('token') || '';

    this.form = this.formBuilder.group({
      job: ['', []],
      location: ['', []],
    });
  }

  ngAfterContentChecked() {
    this.token = this.persistanceService.get('token') || '';
  }

  selectReviewDetailsJob(job) {
    for (let i = 0; i < this.jobs.length; i++) {
      this.jobs[i].open = false;
    }
    job.open = true;
    this.selectedJob = job;
  }

  focusJobInput() {
    this.hasHelper = false;

    this.filterJobs();
  }

  blurJobInput() {
    if (this.form.value.job == '' && this.form.value.location == '') {
      // this.hasHelper = true;
    }
    this.filterJobs();
  }

  focusLocationInput() {
    this.hasHelper = false;

    this.filterJobs();
  }

  blurLocationInput() {
    if (this.form.value.job == '' && this.form.value.location == '') {
      // this.hasHelper = true;
    }

    this.filterJobs();
  }

  closeShowJobs() {
    this.hasHelper = true;
    this.hasSearch = false;
  }

  filterJobs() {
    if (this.form.value.job != '' && this.form.value.location != '') {
      this.hasSearch = true;
      for (let i = 0; i < this.jobs.length; i++) {
        console.log(this.jobs[i].company.name);
        if (
          this.jobs[i].company.name
            .toLocaleLowerCase()
            .indexOf(this.form.value.job.toLocaleLowerCase()) != -1 &&
          this.jobs[i].location
            .toLocaleLowerCase()
            .indexOf(this.form.value.location.toLocaleLowerCase()) != -1
        ) {
          this.jobs[i]['filter'] = false;
        } else {
          this.jobs[i]['filter'] = true;
        }
      }
    } else if (this.form.value.job != '' && this.form.value.location == '') {
      this.hasSearch = true;
      for (let i = 0; i < this.jobs.length; i++) {
        if (
          this.jobs[i].company.name
            .toLocaleLowerCase()
            .indexOf(this.form.value.job.toLocaleLowerCase()) != -1
        ) {
          this.jobs[i]['filter'] = false;
        } else {
          this.jobs[i]['filter'] = true;
        }
      }
    } else if (this.form.value.job == '' && this.form.value.location != '') {
      this.hasSearch = true;
      for (let i = 0; i < this.jobs.length; i++) {
        if (
          this.jobs[i].location
            .toLocaleLowerCase()
            .indexOf(this.form.value.location.toLocaleLowerCase()) != -1
        ) {
          this.jobs[i]['filter'] = false;
        } else {
          this.jobs[i]['filter'] = true;
        }
      }
    } else if (this.form.value.job == '' && this.form.value.location == '') {
      this.hasSearch = true;
      for (let i = 0; i < this.jobs.length; i++) {
        this.jobs[i]['filter'] = false;
      }
    }
  }

  selectPopularSearch(search) {
    if (search.type == 'location') {
      this.form.value.location = search.text;
      document.getElementById('location-search')['value'] = search.text;
    } else if (search.type == 'title') {
      this.form.value.job = search.text;
      document.getElementById('job-search')['value'] = search.text;
    }

    this.filterJobs();
  }
}
