import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-seo-video',
  templateUrl: './seo-video.component.html',
  styleUrls: ['./seo-video.component.scss'],
})
export class SeoVideoComponent implements OnInit {
  @Input() data: any;
  video: SafeResourceUrl;
  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.video = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.src);
  }
}
