<section class="bg-white py-56 relative z-0">
  <form [formGroup]="form" class="w-760 flex mx-auto">
    <div class="form-group mr-16 flex-grow label-in">
      <label for="">Job</label>
      <input
        id="job-search"
        type="text"
        class="pl-56"
        placeholder="Kewords, skills, job title"
        (focus)="focusJobInput()"
        (blur)="blurJobInput()"
        formControlName="job"
      />
      <app-icon [icon]="'search'"></app-icon>
    </div>

    <div class="form-group mr-16 flex-grow label-in">
      <label for="">Location</label>
      <input
        id="location-search"
        type="text"
        class="pl-90"
        placeholder="City, state or remote"
        (focus)="focusLocationInput()"
        (blur)="blurLocationInput()"
        formControlName="location"
      />
      <app-icon [icon]="'location'"></app-icon>
    </div>
  </form>

  <div *ngIf="token != ''">
    <h2 class="text-2xl text-center mt-64 mb-24 font-bold">Popular Searches</h2>
    <div class="container mx-auto flex flex-wrap justify-center">
      <app-popular-search
        *ngFor="let ps of popularSearches"
        [search]="ps"
        (select)="selectPopularSearch($event)"
      ></app-popular-search>
    </div>
  </div>

  <ng-container *ngIf="token == ''">
    <h2 class="text-2xl text-center mt-64 mb-24 font-bold">
      Are You Looking For A Job!
    </h2>
    <div class="flex justify-center">
      <button
        class="btn btn-blue"
        [routerLink]="['/register']"
        [queryParams]="{ type: 'user' }"
        routerLinkActive="router-link-active"
      >
        Get started today
      </button>
    </div>
  </ng-container>
</section>

<div
  class="container mx-auto grid grid-cols-2 gap-32 py-24"
  *ngIf="token != '' || (token == '' && hasSearch)"
>
  <div>
    <button (click)="closeShowJobs()" *ngIf="token == ''">
      <app-icon [icon]="'icon'" [fill]="'blue'"></app-icon>
    </button>
    <app-job-description
      *ngFor="let job of jobs"
      [job]="job"
      (click)="selectReviewDetailsJob(job)"
      [ngClass]="{ 'border border-blue-light': job.open, none: job.filter }"
    >
    </app-job-description>
  </div>
  <app-quick-tips *ngIf="selectedJob == null"></app-quick-tips>
  <app-job-details *ngIf="selectedJob != null" [jobDetails]="selectedJob">
  </app-job-details>
</div>

<ng-container *ngIf="token == ''">
  <app-how-works *ngIf="hasHelper" [data]="howWorks"></app-how-works>
  <app-seo-video *ngIf="hasHelper" [data]="videoCeo"></app-seo-video>
  <app-call-to-action
    *ngIf="hasHelper"
    [title]="'Let employers find you'"
    [button]="'Upload your resume'"
  ></app-call-to-action>
  <app-our-partners *ngIf="hasHelper"></app-our-partners>
  <app-blog-section *ngIf="hasHelper"></app-blog-section>
  <app-news-letter *ngIf="hasHelper"></app-news-letter>
</ng-container>
