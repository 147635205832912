import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {
  trigger,
  state,
  style,
  animate,
  keyframes,
  transition,
  query,
  stagger,
  group,
  animateChild,
  sequence,
} from "@angular/animations";

export const menuAnimation = trigger("menuAnimation", [
  transition(":enter", [
    query(".menu-animation", [
      style({opacity: 0, transform: "translateY(100px)"}),
      stagger(200, [
        animate(
          "1s cubic-bezier(.19,1,.22,1)",
          keyframes([
            style({
              opacity: 0,
              transformOrigin: "50% 0",
              transform: "translateX(-50px) scale3d(0.8,0.8,0.8)",
              offset: 0,
            }),
            style({
              opacity: 1,
              transformOrigin: "50% 0",
              transform: "translateX(0) scale3d(1,1,1)",
              offset: 1,
            }),
          ])
        ),
      ]),
    ]),
  ]),
  transition(":leave", [
    query(".menu-animation", [
      style({opacity: 1}),
      stagger(0, [
        animate("300ms cubic-bezier(0.35, 0, 0.25, 1)", style({opacity: 0})),
      ]),
    ]),
  ]),
]);

export const fadeUp = trigger("fadeUp", [
  transition(":enter", [
    style({
      opacity: 0,
      transform: "translateY(20px)",
    }),
    animate(
      ".2s",
      style({
        opacity: 1,
        transform: "translateY(0)",
      })
    ),
  ]),
  transition(":leave", [
    style({
      opacity: 1,
      // transform: "translateY(0)",
    }),
    animate(
      ".2s",
      style({
        opacity: 0,
        transform: "translateY(20px)",
      })
    ),
  ]),
]);

export const slideInOut = trigger("slideInOut", [
  transition(":enter", [
    style({
      opacity: 0,
      transform: "translateX(100%)",
    }),
    animate(
      ".3s",
      style({
        opacity: 1,
        transform: "translateY(0)",
      })
    ),
  ]),
  transition(":leave", [
    style({
      opacity: 1,
      transform: "translateX(0)",
    }),
    animate(
      ".3s",
      style({
        opacity: 0,
        transform: "translateX(-100%)",
      })
    ),
  ]),
]);
