import { Component, OnInit } from '@angular/core';
import { PersistanceService } from 'src/app/services/persistance-service.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  userType = 'user';
  token = '';
  constructor(private persistanceService: PersistanceService) {}

  ngOnInit(): void {
    this.userType = this.persistanceService.get('userType') || 'user';
    this.token = this.persistanceService.get('token') || '';
  }

  ngAfterContentChecked() {
    this.userType = this.persistanceService.get('userType') || 'user';
    this.token = this.persistanceService.get('token') || '';
  }

  ngAfterViewChecked(): void {
    this.userType = this.persistanceService.get('userType') || 'user';
    this.token = this.persistanceService.get('token') || '';
  }

  switchTallent() {
    this.persistanceService.set('userType', 'user');
    this.userType = 'user';
  }

  switchCompany() {
    this.persistanceService.set('userType', 'company');
    this.userType = 'company';
  }
}
