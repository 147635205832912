import { Component, OnInit } from '@angular/core';
import { PersistanceService } from 'src/app/services/persistance-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss'],
  host: {
    class: 'w-full h-full absolute',
  },
})
export class PublicProfileComponent implements OnInit {
  private sub: any;
  userId: any;
  userType = 'user';
  token = '';
  loaded = false;
  profileData: any;
  currentTab = 1;

  constructor(
    private persistanceService: PersistanceService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.userId = params['id'];
    });

    this.userType = this.persistanceService.get('userType') || 'user';
    this.token = this.persistanceService.get('token') || '';

    if (this.userType == 'company') {
      this.authService
        .getTalentPublicProfile(this.token, this.userId)
        .subscribe(
          (res) => {
            this.profileData = res['data'];
            this.loaded = true;

            console.log(this.profileData);
          },
          (error) => {
            debugger;
          }
        );
    }
  }

  switchTab(id) {
    this.currentTab = id;
  }

  formatNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
