<div
  class="w-530 flex flex-col justify-right items-center h-loginh md:h-full bg-white z-2 transition duration-300 absolute top-64 md:top-0 right-0 z-10"
>
  <div class="flex w-full">
    <button
      (click)="switchTallent()"
      class="login-tab"
      [ngClass]="{ active: userType == 'user' }"
    >
      <app-icon [icon]="'talent'"></app-icon>
      <label>Talent</label>
    </button>
    <button
      (click)="switchCompany()"
      class="login-tab active"
      [ngClass]="{ active: userType == 'company' }"
    >
      <app-icon [icon]="'company'"></app-icon>
      <label>Company</label>
    </button>
  </div>
  <div class="flex-grow flex flex-col items-center justify-center">
    <div
      class="w-full md:w-256"
      *ngIf="userType == 'user'"
      [ngClass]="{ active: userType == 'user' }"
      [formGroup]="formTalent"
    >
      <h2 class="text-center text-xl font-semibold mb-48">Register Talent</h2>
      <div class="form-group mb-24">
        <input
          type="text"
          placeholder="First Name"
          class="px-16"
          formControlName="first_name"
          [ngClass]="{ 'error-input': talentRegisterErrorObj.first_name != '' }"
        />
        <p *ngIf="talentRegisterErrorObj.first_name != ''" class="error">
          {{ talentRegisterErrorObj.first_name }}
        </p>
      </div>
      <div class="form-group mb-24">
        <input
          type="text"
          placeholder="Middle Name"
          class="px-16"
          formControlName="middle_name"
          [ngClass]="{
            'error-input': talentRegisterErrorObj.middle_name != ''
          }"
        />

        <p *ngIf="talentRegisterErrorObj.middle_name != ''" class="error">
          {{ talentRegisterErrorObj.middle_name }}
        </p>
      </div>
      <div class="form-group mb-24">
        <input
          type="text"
          placeholder="Last Name"
          class="px-16"
          formControlName="last_name"
          [ngClass]="{ 'error-input': talentRegisterErrorObj.last_name != '' }"
        />

        <p *ngIf="talentRegisterErrorObj.last_name != ''" class="error">
          {{ talentRegisterErrorObj.last_name }}
        </p>
      </div>

      <div class="form-group mb-24">
        <input
          type="email"
          placeholder="Email"
          class="px-16"
          formControlName="username"
          [ngClass]="{ 'error-input': talentRegisterErrorObj.email != '' }"
        />

        <p *ngIf="talentRegisterErrorObj.email != ''" class="error">
          {{ talentRegisterErrorObj.email }}
        </p>
      </div>
      <div class="form-group mb-24">
        <app-icon
          *ngIf="companyTypePassword == 'password'"
          [icon]="'eye'"
          class="absolute right-16 top-8 mt-3"
          (click)="showCompanyTypePassword()"
        ></app-icon>
        <app-icon
          *ngIf="companyTypePassword == 'text'"
          [icon]="'eye-no'"
          class="absolute right-16 top-8"
          (click)="showCompanyTypePassword()"
        ></app-icon>
        <input
          type="{{ companyTypePassword }}"
          placeholder="Password"
          class="px-16"
          formControlName="password"
          [ngClass]="{ 'error-input': talentRegisterErrorObj.password != '' }"
        />

        <p *ngIf="talentRegisterErrorObj.password != ''" class="error">
          {{ talentRegisterErrorObj.password }}
        </p>
      </div>
      <div class="form-group mb-24">
        <app-icon
          *ngIf="companyTypeRepeatPassword == 'password'"
          [icon]="'eye'"
          class="absolute right-16 top-8 mt-3"
          (click)="showCompanyTypeRepeatPassword()"
        ></app-icon>
        <app-icon
          *ngIf="companyTypeRepeatPassword == 'text'"
          [icon]="'eye-no'"
          class="absolute right-16 top-8"
          (click)="showCompanyTypeRepeatPassword()"
        ></app-icon>
        <input
          type="{{ companyTypeRepeatPassword }}"
          placeholder="Password Confirm"
          formControlName="repeatPassword"
          class="px-16"
          [ngClass]="{
            'error-input': talentRegisterErrorObj.confirmPassword != ''
          }"
        />

        <p *ngIf="talentRegisterErrorObj.confirmPassword != ''" class="error">
          {{ talentRegisterErrorObj.confirmPassword }}
        </p>
      </div>

      <label for="newsletter" class="mb-16 block">
        <input type="checkbox" id="newsletter" />
        Subscribe to news letter
      </label>

      <label
        for="register"
        class="mb-16 block"
        [ngClass]="{
          red:
            !talentRegisterErrorObj.acceptTerms &&
            !talentRegisterErrorObj.initAcceptTerms
        }"
      >
        <input type="checkbox" id="register" (click)="acceptTerms()" />
        I accept Terms of Use
      </label>

      <div style="color: red" *ngIf="talentRegisterError != ''">
        {{ talentRegisterError }}
      </div>

      <button class="btn btn-blue w-full" (click)="registerTalent()">
        Register
      </button>
    </div>

    <div
      class="w-full md:w-256"
      *ngIf="userType == 'company'"
      [ngClass]="{ active: userType == 'company' }"
      [formGroup]="formCompany"
    >
      <h2 class="text-center text-xl font-semibold mb-48">Register Company</h2>
      <div class="form-group mb-24">
        <input
          type="text"
          placeholder="Name"
          class="px-16"
          formControlName="name"
          [ngClass]="{ 'error-input': companyRegisterErrorObj.name != '' }"
        />

        <p *ngIf="companyRegisterErrorObj.name != ''" class="error">
          {{ companyRegisterErrorObj.name }}
        </p>
      </div>
      <div class="form-group mb-24">
        <input
          type="email"
          placeholder="Email"
          class="px-16"
          formControlName="username"
          [ngClass]="{ 'error-input': companyRegisterErrorObj.email != '' }"
        />

        <p *ngIf="companyRegisterErrorObj.email != ''" class="error">
          {{ companyRegisterErrorObj.email }}
        </p>
      </div>
      <div class="form-group mb-24">
        <app-icon
          *ngIf="tallentTypePassword == 'password'"
          [icon]="'eye'"
          class="absolute right-16 top-8 mt-3"
          (click)="showTallentTypePassword()"
        ></app-icon>
        <app-icon
          *ngIf="tallentTypePassword == 'text'"
          [icon]="'eye-no'"
          class="absolute right-16 top-8"
          (click)="showTallentTypePassword()"
        ></app-icon>
        <input
          type="{{ tallentTypePassword }}"
          placeholder="Password"
          class="px-16"
          formControlName="password"
          [ngClass]="{ 'error-input': companyRegisterErrorObj.password != '' }"
        />

        <p *ngIf="companyRegisterErrorObj.password != ''" class="error">
          {{ companyRegisterErrorObj.password }}
        </p>
      </div>
      <div class="form-group mb-24">
        <app-icon
          *ngIf="tallentTypeRepeatPassword == 'password'"
          [icon]="'eye'"
          class="absolute right-16 top-8 mt-3"
          (click)="showTallentTypeRepeatPassword()"
        ></app-icon>
        <app-icon
          *ngIf="tallentTypeRepeatPassword == 'text'"
          [icon]="'eye-no'"
          class="absolute right-16 top-8"
          (click)="showTallentTypeRepeatPassword()"
        ></app-icon>
        <input
          type="{{ tallentTypeRepeatPassword }}"
          placeholder="Password confirm"
          class="px-16"
          formControlName="repeatPassword"
          [ngClass]="{
            'error-input': companyRegisterErrorObj.confirmPassword != ''
          }"
        />

        <p *ngIf="companyRegisterErrorObj.confirmPassword != ''" class="error">
          {{ companyRegisterErrorObj.confirmPassword }}
        </p>
      </div>
      <label for="newsletter" class="mb-16 block"
        ><input type="checkbox" id="newsletter" class="px-16" /> Subscribe to
        news letter</label
      >

      <label
        for="register"
        class="mb-16 block"
        [ngClass]="{
          red:
            !companyRegisterErrorObj.acceptTerms &&
            !companyRegisterErrorObj.initAcceptTerms
        }"
        ><input type="checkbox" id="register" (click)="acceptCompanyTerms()" />
        I accept Terms of Use
      </label>

      <div style="color: red" *ngIf="companyRegisterError != ''">
        {{ companyRegisterError }}
      </div>

      <button class="btn btn-blue w-full" (click)="registerCompany()">
        Register
      </button>
    </div>
    <p class="text-center mt-32">
      Have account?
      <span
        [routerLink]="['/login']"
        routerLinkActive="router-link-active"
        class="link"
        >Sign in</span
      >
    </p>
  </div>
  <p class="text-xs opacity-50 mb-16">
    Copyright © 2020 See2hire. All Rights Reserved.
  </p>
  <div class="logo">
    <app-logo></app-logo>
    <h1
      class="text-2xl text-white uppercase text-right tracking-title mt-48"
      *ngIf="userType == 'user'"
    >
      Find best job in <br />
      interactive Experience
    </h1>

    <h1
      class="text-2xl text-white uppercase text-right tracking-title mt-48"
      *ngIf="userType == 'company'"
    >
      Search Talent through an <br />
      interactive Experience
    </h1>
  </div>
</div>
