import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss'],
  host: {
    class: 'py-56 text-center flex flex-col items-center'
  }
})
export class CallToActionComponent implements OnInit {
  @Input() button: string;
  @Input() title: string;
  constructor() { }

  ngOnInit(): void {
  }

}
