<button
  (click)="openMore($event)"
  class="absolute top-16 right-8 hover:opacity-50 active:opacity-100 transition duration-300"
>
  <app-icon class="" [icon]="'more'" class="h-20 w-20"></app-icon>
</button>
<ul *ngIf="job.more" class="absolute px-16 top-40 right-16">
  <li class="px-16 hover:bg-body cursor-pointer">Mark as favorite</li>
  <li class="px-16 hover:bg-body cursor-pointer">Remove</li>
</ul>
<div class="flex mb-16">
  <div class="w-56 h-56 overflow-hidden rounded-1/2">
    <img
      src="{{ job.company.img }}"
      alt=""
      class="w-full h-full object-cover"
    />
  </div>
  <div class="ml-16">
    <h4 class="text-lg">{{ job.company.name }}</h4>
    <p class="text-sm opacity-75">{{ job.company.sizeInfo }}</p>
  </div>
</div>

<p class="job-desc mb-16">{{ job.shortDescription }}</p>

<div class="flex justify-between">
  <div>
    <p class="flex items-center">
      <app-icon [icon]="'location'" class="w-20 h-20 mr-8"></app-icon> {{
      job.location }}
    </p>
    <p class="text-sm opacity-50">{{ job.postedInfo }}</p>
  </div>

  <button class="btn btn-stroke btn-sm self-end">Apply</button>
</div>
