<app-header
  *ngIf="
    token === '' &&
    router.url !== '/login' &&
    router.url !== '/register' &&
    router.url !== '/register?type=company' &&
    router.url !== '/register?type=user' &&
    router.url !== '/forgot'
  "
  [lineOne]="lineOne"
  [lineTwo]="lineTwo"
  (logginAction)="refreshToken()"
></app-header>
<app-user-header
  *ngIf="
    token !== '' &&
    router.url !== '/login' &&
    router.url !== '/register' &&
    router.url !== '/register?type=company' &&
    router.url !== '/register?type=user' &&
    router.url !== '/forgot'
  "
  (logOutAction)="refreshToken()"
></app-user-header>
<router-outlet></router-outlet>
<div
  class="login-screen"
  *ngIf="
    router.url === '/login' ||
    router.url === '/register' ||
    router.url === '/register?type=company' ||
    router.url === '/register?type=user' ||
    router.url === '/forgot'
  "
></div>

<app-footer
  *ngIf="
    router.url !== '/login' &&
    router.url !== '/register' &&
    router.url !== '/register?type=company' &&
    router.url !== '/register?type=user' &&
    router.url !== '/forgot'
  "
></app-footer>
