import { Component, Output, EventEmitter } from '@angular/core';
import { OnInit, OnDestroy, ElementRef } from '@angular/core';

import videojs from 'video.js';
import * as RecordRTC from 'recordrtc';
import * as Record from 'videojs-record/dist/videojs.record.js';
import { AuthService } from 'src/app/services/auth.service';
import { PersistanceService } from 'src/app/services/persistance-service.service';

@Component({
  selector: 'app-talent-record-video',
  templateUrl: './talent-record-video.component.html',
  styleUrls: ['./talent-record-video.component.scss'],
  host: {
    style: 'block w-991 bg-white rounded-8 p-32',
  },
})
export class TalentRecordVideoComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() uploadVideo: EventEmitter<any> = new EventEmitter();

  activeSection = 0;
  token = '';
  isButtonExpose = false;
  isPauseExpose = false;
  isResumeExpose = false;
  timer = 0;
  isPauseOrStop = true;
  setInterval = false;

  setIntervalTimer;

  private _elementRef: ElementRef;

  // index to create unique ID for component
  idx = 'clip1';

  private config: any;
  private player: any;
  private plugin: any;

  // constructor initializes our declared vars
  constructor(
    elementRef: ElementRef,
    private authService: AuthService,
    private persistanceService: PersistanceService
  ) {
    this.player = false;

    // save reference to plugin (so it initializes)
    this.plugin = Record;

    // video.js configuration
    this.config = {
      controls: true,
      autoplay: false,
      fluid: false,
      loop: false,
      width: 620,
      height: 480,
      bigPlayButton: false,
      controlBar: {
        volumePanel: false,
        pip: false,
      },
      plugins: {
        /*
        // wavesurfer section is only needed when recording audio-only
        wavesurfer: {
            backend: 'WebAudio',
            waveColor: '#36393b',
            progressColor: 'black',
            debug: true,
            cursorWidth: 1,
            displayMilliseconds: true,
            hideScrollbar: true,
            plugins: [
                // enable microphone plugin
                WaveSurfer.microphone.create({
                    bufferSize: 4096,
                    numberOfInputChannels: 1,
                    numberOfOutputChannels: 1,
                    constraints: {
                        video: false,
                        audio: true
                    }
                })
            ]
        },
        */
        // configure videojs-record plugin
        record: {
          audio: true,
          video: true,
          debug: true,
          maxLength: 60,
          pip: false,
        },
      },
    };
  }

  ngOnInit() {
    this.token = this.persistanceService.get('token') || '';
    //  var elems = document
    //    .getElementsByClassName('vjs-record-button')
    //   for (let i = 0; i < elems.length; i++){
    //     elems[i].addEventListener('click', functio)
    //   }
  }

  // use ngAfterViewInit to make sure we initialize the videojs element
  // after the component template itself has been rendered
  ngAfterViewInit() {
    // ID with which to access the template's video element
    let el = 'video_' + this.idx;

    // setup the player via the unique element ID
    this.player = videojs(document.getElementById(el), this.config, () => {
      console.log('player ready! id:', el);
      // print version information at startup
      var msg =
        'Using video.js ' +
        videojs.VERSION +
        ' with videojs-record ' +
        videojs.getPluginVersion('record') +
        ' and recordrtc ' +
        RecordRTC.version;
      videojs.log(msg);
    });
    //this.addPauseButton()

    // device is ready
    this.player.on('deviceReady', () => {
      console.log('device is ready!');
    });

    // user clicked the record button and started recording
    this.player.on('startRecord', () => {
      this.addPauseButton();
      this.hideButtons();
      this.activeSection = 1;
      this.isPauseOrStop = false;

      if (!this.setInterval) {
        this.setInterval = true;
        this.setIntervalTimer = setInterval(() => {
          if (this.isPauseOrStop == false) {
            this.timer++;
          }
          if (this.timer == 20) {
            this.activeSection = 2;
          } else if (this.timer == 40) {
            this.activeSection = 3;
          }
        }, 1000);
      }

      console.log('started recording!');
    });

    // user completed recording and stream is available
    this.player.on('finishRecord', () => {
      // recordedData is a blob object containing the recorded data that
      // can be downloaded by the user, stored on server etc.
      // this.player.record().saveAs({ video: 'my-video-file-name.webm' });

      this.isPauseOrStop = true;
      this.activeSection = 0;
      this.showButtons();
      this.hideResumeButton();
      this.hidePauseButton();
      this.timer = 0;
      setTimeout(() => {
        document.getElementsByClassName('vjs-paused')[1]['style'].display =
          'block';
      }, 200);
      //this.authService.updateProfileVideo(this.token, this.player.recordedData);
      console.log('finished recording: ', this.player.recordedData);
    });

    // error handling
    this.player.on('error', (element, error) => {
      console.warn(error);
    });

    this.player.on('deviceError', () => {
      console.error('device error:', this.player.deviceErrorCode);
    });
  }

  // use ngOnDestroy to detach event handlers and remove the player
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
      this.player = false;
    }
  }
  showButtons() {
    if (!this.isButtonExpose) {
      let saveButton = this.player.controlBar.addChild('button');
      let saveButtonDom = saveButton.el();
      saveButtonDom.innerHTML = '';
      saveButtonDom.id = 'savebtn';
      let that = this;

      let delButton = this.player.controlBar.addChild('button');
      let delButtonDom = delButton.el();

      delButtonDom.innerHTML = '';
      delButtonDom.id = 'delbtn';
      delButtonDom.style.color = 'red';

      delButtonDom.onclick = function () {
        that.activeSection = 0;
        that.timer = 0;
        that.hideButtons();
        that.hidePauseButton();
        that.hideResumeButton();
        setTimeout(() => {
          document.getElementsByClassName('vjs-paused')[1]['style'].display =
            'none';
        }, 100);
      };
      saveButtonDom.onclick = function () {
        that.authService
          .updateProfileVideo(that.token, that.player.recordedData)
          .subscribe(
            (res) => {
              that.timer = 0;
              that.uploadVideo.emit();
            },
            (error) => {
              alert(error);
            }
          );
        that.hideButtons();
        that.hidePauseButton();
        that.hideResumeButton();
        alert('Save');
      };
      this.isButtonExpose = true;
    } else {
      this.exposeButtons();
    }
  }
  hideButtons() {
    if (this.isButtonExpose) {
      document.getElementById('savebtn').style.display = 'none';
      document.getElementById('delbtn').style.display = 'none';
    }
  }
  exposeButtons() {
    document.getElementById('savebtn').style.display = 'block';
    document.getElementById('delbtn').style.display = 'block';
  }
  hideResumeButton() {
    if (document.getElementById('resumebtn'))
      document.getElementById('resumebtn').style.display = 'none';
  }
  hidePauseButton() {
    document.getElementById('pausebtn').style.display = 'none';
  }
  showResumeButton() {
    document.getElementById('resumebtn').style.display = 'block';
  }
  showPauseButton() {
    document.getElementById('pausebtn').style.display = 'block';
  }
  addPauseButton() {
    if (!this.isPauseExpose) {
      let pauseButton = this.player.controlBar.addChild('button');
      let pauseButtonDom = pauseButton.el();
      pauseButtonDom.innerHTML = '<span></span>';
      pauseButtonDom.id = 'pausebtn';
      let that = this;
      pauseButtonDom.onclick = function () {
        that.player.record().pause();

        that.addResumeButton();
        // that.showButtons();
        that.hidePauseButton();
        that.isPauseOrStop = true;
      };
      this.isPauseExpose = true;
    } else {
      this.showPauseButton();
    }
  }
  addResumeButton() {
    if (!this.isResumeExpose) {
      let that = this;
      let resumeButton = this.player.controlBar.addChild('button');
      let resumeButtonDom = resumeButton.el();
      resumeButtonDom.innerHTML = '';
      resumeButtonDom.id = 'resumebtn';
      resumeButtonDom.onclick = function () {
        that.player.record().resume();
        that.hideButtons();
        that.showPauseButton();
        that.hideResumeButton();
        that.isPauseOrStop = false;
      };
      this.isResumeExpose = true;
    } else {
      this.showResumeButton();
    }
  }

  closeVideo() {
    this.close.emit();
    clearInterval(this.setIntervalTimer);
  }
}
