<h2 class="text-center font-bold text-2xl mb-8">Newsletter</h2>
<p class="text-center font-medium text-lg mb-24">
  Subscribe to our newsletter and stay updated.
</p>

<form action="" class="w-full md:w-2/3 lg:w-1/3 flex mx-auto">
  <div class="form-group mr-16 flex-grow">
    <input class="px-16" type="text" placeholder="Email" />
  </div>
  <button class="btn btn-blue">Subscribe</button>
</form>
