<div style="background-color: salmon">
  <div class="flex mb-16">
    <div class="ml-16">
      <h4 class="text-lg">{{ talentDetails.name }}</h4>
      <p class="text-sm opacity-75" *ngFor="let sk of talentDetails.skills">
        {{ sk }}
      </p>
    </div>
  </div>

  <p class="job-desc mb-16">
    {{ talentDetails.location.name }} {{ talentDetails.location.time }}
  </p>

  <div class="flex justify-between">
    <div>
      <p class="flex items-center">
        <app-icon [icon]="'location'" class="w-20 h-20 mr-8"></app-icon>
        {{ talentDetails.description }}
      </p>
      <p class="text-sm opacity-50">{{ talentDetails.videoCardUrl }}</p>
    </div>

    <button class="btn btn-stroke btn-sm self-end">Hire</button>
  </div>
</div>
