import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {ReactiveFormsModule} from "@angular/forms";

import {AppRoutingModule} from "./app-routing.module";

import {AppComponent} from "./app.component";
import {HeaderComponent} from "./components/header/header.component";
import {UserHeaderComponent} from "./components/user-header/user-header.component";
import {MainComponent} from "./components/main/main.component";
import {TallentContentComponent} from "./components/tallent-content/tallent-content.component";
import {CompanyContentComponent} from "./components/company-content/company-content.component";
import {LogoComponent} from "./components/logo/logo.component";
import {IconComponent} from "./components/icon/icon.component";
import {HowWorksComponent} from "./components/how-works/how-works.component";
import {SeoVideoComponent} from "./components/seo-video/seo-video.component";
import {BlogSectionComponent} from "./components/blog-section/blog-section.component";
import {NewsLetterComponent} from "./components/news-letter/news-letter.component";
import {OurPartnersComponent} from "./components/our-partners/our-partners.component";
import {FooterComponent} from "./components/footer/footer.component";
import {CallToActionComponent} from "./components/call-to-action/call-to-action.component";
import {BlogElementComponent} from "./components/blog-section/blog-element/blog-element.component";
import {TalentRecordVideoComponent} from "./components/talent-record-video/talent-record-video.component";

import {LoginComponent} from "./components/login/login.component";
import {JobDescriptionComponent} from "./components/tallent-content/job-description/job-description.component";
import {JobDetailsComponent} from "./components/tallent-content/job-details/job-details.component";
import {QuickTipsComponent} from "./components/quick-tips/quick-tips.component";
import {RegisterComponent} from "./components/register/register.component";
import {ForgotComponent} from "./components/forgot/forgot.component";
import {CompanyOfferComponent} from "./components/company-content/company-offer/company-offer.component";
import {TalentSummaryComponent} from "./components/company-content/talent-summary/talent-summary.component";
import {TalentProfileComponent} from "./components/company-content/talent-profile/talent-profile.component";
import {HttpClientModule} from "@angular/common/http";
import {PopularSearchComponent} from "./components/tallent-content/popular-search/popular-search.component";
import {AvatarComponent} from "./components/avatar/avatar.component";
import {ProfileComponent} from "./components/profile/profile.component";
import {PublicProfileComponent} from "./components/public-profile/public-profile.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { LoaderComponent } from './loader/loader.component';
import { CompanyProfileComponent } from './components/profile/company-profile/company-profile.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    UserHeaderComponent,
    MainComponent,
    TallentContentComponent,
    CompanyContentComponent,
    LogoComponent,
    IconComponent,
    HowWorksComponent,
    SeoVideoComponent,
    BlogSectionComponent,
    NewsLetterComponent,
    OurPartnersComponent,
    FooterComponent,
    CallToActionComponent,
    BlogElementComponent,
    TalentRecordVideoComponent,
    LoginComponent,

    JobDescriptionComponent,
    JobDetailsComponent,
    QuickTipsComponent,
    RegisterComponent,
    ForgotComponent,
    CompanyOfferComponent,
    TalentSummaryComponent,
    TalentProfileComponent,
    PopularSearchComponent,
    AvatarComponent,
    ProfileComponent,
    PublicProfileComponent,
    LoaderComponent,
    CompanyProfileComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
