<section class="bg-white py-56">
  <form [formGroup]="form" class="w-search flex mx-auto">
    <div class="form-group mr-16 flex-grow label-in">
      <label for="">Talent</label>
      <input
        type="text"
        class="pl-72"
        placeholder="Kewords, skills, job title"
        (focus)="focusTalentInput()"
        (blur)="blurTalentInput()"
        formControlName="talent"
      />
      <app-icon [icon]="'search'"></app-icon>
    </div>
  </form>
  <ng-container *ngIf="token == ''">
    <h2 class="text-2xl text-center mt-64 mb-24 font-bold">
      Are You Looking For A Talent!
    </h2>
    <div class="flex justify-center">
      <button
        class="btn btn-blue mx-auto"
        [routerLink]="['/register']"
        [queryParams]="{ type: 'company' }"
      >
        Get started today
      </button>
    </div>
  </ng-container>
</section>
<div class="container mx-auto grid grid-cols-6 pt-16">
  <div class="col-span-2"></div>
  <div class="col-span-4" *ngIf="token != '' || (token == '' && hasSearch)">
    <span (click)="closeShowJobs()">close</span>
    <app-talent-profile
      [talent]="talent"
      *ngFor="let talent of talents"
      (click)="selectReviewDetailsTalent(talent)"
      [ngClass]="{ none: talent.filter }"
      class="relative"
    ></app-talent-profile>
  </div>
</div>

<app-talent-summary
  *ngIf="selectedTalent != null"
  [talentDetails]="selectedTalent"
>
</app-talent-summary>

<ng-container *ngIf="token == ''">
  <app-how-works *ngIf="hasHelper" [data]="howWorks"></app-how-works>
  <app-seo-video *ngIf="hasHelper" [data]="videoCeo"></app-seo-video>
  <app-call-to-action
    *ngIf="hasHelper"
    [title]="'Find the right person for your job'"
    [button]="'Post your job'"
  ></app-call-to-action>
  <app-our-partners *ngIf="hasHelper"></app-our-partners>
  <app-blog-section *ngIf="hasHelper"></app-blog-section>
  <app-news-letter *ngIf="hasHelper"></app-news-letter>
</ng-container>
