import {Component, OnInit, Input} from "@angular/core";

@Component({
  selector: "app-blog-element",
  templateUrl: "./blog-element.component.html",
  styleUrls: ["./blog-element.component.scss"],
})
export class BlogElementComponent implements OnInit {
  @Input() big: boolean;
  image = "url(/assets/img/blog.jpg)";
  constructor() {}

  ngOnInit(): void {}
  bgImage() {
    if (this.big === true) {
      return {
        "background-image": this.image,
        "background-size": "cover",
      };
    }
  }
  smBgImage() {
    if (this.big === false) {
      return {
        "background-image": this.image,
        "background-size": "cover",
      };
    }
  }
}
