<section class="bg-white">
  <div class="container mx-auto py-90">
    <div class="grid grid-cols-2 gap-40">
      <div class="ratio-16-9 relative rounded-8 overflow-hidden shadow-lg">
        <iframe
          class="w-full h-full absolute top-0 left-0"
          [src]="video"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="flex justify-center items-center">
        <div class="w-2/3">
          <h2 class="text-xl font-bold mb-24">{{ data.title }}</h2>
          <p>
            {{ data.content }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
