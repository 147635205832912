<div class="w-full h-full overflow-hidden rounded-1/2 bg-white relative">
  <img
    src="https://www.see2hire.com{{ avatarUrl }}"
    alt=""
    class="w-full h-full object-cover"
  />
  <svg
    *ngIf="
      avatarUrl == undefined ||
      avatarUrl == '' ||
      avatarUrl == '/public/user_images/'
    "
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 56 56"
  >
    <mask id="a" width="56" height="56" x="0" y="0" maskUnits="userSpaceOnUse">
      <path
        fill="#fff"
        d="M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28z"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#EAEAEA"
        d="M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28z"
      />
      <mask
        id="b"
        width="56"
        height="22"
        x="0"
        y="34"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M35.28 40.2808c0 2.24 2.6597 2.9235 3.9895 3.2653l12.7692 3.2817c1.7735.6243 3.2131 1.9677 3.9613 3.6809V56H0v-5.4913c.7482-1.7132 2.1878-3.0566 3.9613-3.6809l12.7746-3.2831c1.328-.3413 3.9841-1.0039 3.9841-3.2639V34.16h14.56v6.1208z"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#9A9A9A"
          d="M35.28 40.2808c0 2.24 2.6597 2.9235 3.9895 3.2653l12.7692 3.2817c1.7735.6243 3.2131 1.9677 3.9613 3.6809V56H0v-5.4913c.7482-1.7132 2.1878-3.0566 3.9613-3.6809l12.7746-3.2831c1.328-.3413 3.9841-1.0039 3.9841-3.2639V34.16h14.56v6.1208z"
        />
        <path
          fill="#6B6B6B"
          fill-rule="evenodd"
          d="M28.111 6.16h.0969a.1429.1429 0 01.0097.0003c1.8468.0064 4.5907.114 6.3943 1.1375 1.8711 1.0619 4.1131 3.1445 4.7598 6.7941 1.3607 7.6789-1.1737 19.8005-3.5358 23.7456-2.2601 3.7747-5.4988 5.162-7.5635 5.2749a.174.174 0 01-.0504.0076 5.211 5.211 0 01-.111-.0013A5.1956 5.1956 0 0128 43.12a.175.175 0 01-.0504-.0076c-2.0647-.1129-5.3033-1.5002-7.5634-5.2749-2.3622-3.9451-4.8966-16.0667-3.5359-23.7456.6468-3.6496 2.8887-5.7322 4.7598-6.7941 1.8037-1.0236 4.5475-1.131 6.3944-1.1375a.14.14 0 01.0096-.0003h.0969z"
          clip-rule="evenodd"
        />
      </g>
      <path
        fill="#B7B7B7"
        fill-rule="evenodd"
        d="M27.7715 8.96c10.6727.0253 11.5953 7.7293 11.6577 8.4322.5942 6.6876-1.6843 15.7084-3.8219 18.8456-2.26 3.3171-5.4987 4.5363-7.5634 4.6355a1.451 1.451 0 01-.1614.0056 5.9352 5.9352 0 01-.111.0011c-2.1151-.1059-5.3538-1.3251-7.6138-4.6422-2.1443-3.147-4.4304-12.2148-3.8162-18.9087.0624-.6803.7573-8.3944 11.43-8.3691z"
        clip-rule="evenodd"
      />
    </g>
  </svg>
</div>
