import { Component, OnInit } from '@angular/core';
import { PersistanceService } from 'src/app/services/persistance-service.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-company-content',
  templateUrl: './company-content.component.html',
  styleUrls: ['./company-content.component.scss'],
})
export class CompanyContentComponent implements OnInit {
  form: FormGroup;
  token = '';
  hasHelper = true;

  hasSearch = false;
  selectedTalent = null;
  talents = [
    {
      name: 'Irina Peicheva',
      img: 'https://randomuser.me/api/portraits/women/44.jpg',
      skills: ['Front-end developer', 'Angular JS, Web developer'],
      location: {
        name: 'New York, USA',
        time: '11:26am local time',
      },
      description:
        'Angular 2/4/5 Frontend Engineer to transform healthcare and improve health outcomes',
      videoCardUrl: 'https://www.youtube.com/watch?v=QXvxaBYRMLM',
    },
    {
      name: 'Depika Shukar',
      img:
        'https://images-na.ssl-images-amazon.com/images/M/MV5BYTcwYjA3NGQtNDU4NS00OWVkLTlmM2MtM2Q4NzA0ZjkxYTA3XkEyXkFqcGdeQXVyMjc4NTQyNTQ@._V1_UX172_CR0,0,172,256_AL_.jpg',
      skills: ['Front-end developer', 'Angular JS, Web developer'],
      location: {
        name: 'Bangalor, India',
        time: '11:26am local time',
      },
      description:
        'Angular 2/4/5 Frontend Engineer to transform healthcare and improve health outcomes',
      videoCardUrl: 'https://www.youtube.com/watch?v=QXvxaBYRMLM',
    },
  ];

  howWorks = [
    {
      icon: 'account',
      title: 'Create an Account',
      text:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
      icon: 'resume',
      title: 'Upload Your Resume',
      text:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
      icon: 'video-upload',
      title: 'Upload Your Video',
      text:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
  ];
  videoCeo = {
    src: 'https://www.youtube.com/embed/LrLDKYFyLMM',
    title: 'Get COMPANY tips from our SEO',
    content:
      'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th',
  };

  constructor(
    private formBuilder: FormBuilder,
    private persistanceService: PersistanceService
  ) {}

  ngOnInit() {
    this.persistanceService.set('headerLineOne', 'Search Talent through an');
    this.persistanceService.set('headerLineTwo', 'interactive Experience');

    this.token = this.persistanceService.get('token') || '';

    this.form = this.formBuilder.group({
      talent: ['', []],
    });
  }

  focusTalentInput() {
    this.hasHelper = false;
    this.filterTalents();
  }

  blurTalentInput() {
    if (this.form.value.talent == '') {
      //this.hasHelper = true;
    }

    this.filterTalents();
  }

  filterTalents() {
    if (this.form.value.talent == '') {
      this.hasSearch = true;
    } else {
      this.hasSearch = true;
    }
    for (let i = 0; i < this.talents.length; i++) {
      if (
        this.talents[i].name
          .toLocaleLowerCase()
          .indexOf(this.form.value.talent.toLocaleLowerCase())
      ) {
        this.talents[i]['filter'] = true;
      } else {
        this.talents[i]['filter'] = false;
      }
    }
  }

  ngAfterContentChecked() {
    this.token = this.persistanceService.get('token') || '';
  }

  selectReviewDetailsTalent(tl) {
    this.selectedTalent = tl;
  }

  closeShowJobs() {
    this.hasHelper = true;
    this.hasSearch = false;
  }
}
