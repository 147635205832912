import {Component, OnInit, Input} from "@angular/core";

@Component({
  selector: "app-company-profile",
  templateUrl: "./company-profile.component.html",
  styleUrls: ["./company-profile.component.scss"],
  host: {
    class: "block h-main",
  },
})
export class CompanyProfileComponent implements OnInit {
  @Input() profile: any;
  activeTab = "account";
  constructor() {}

  ngOnInit(): void {
    console.log(this.profile);
  }

  changeTab(tab) {
    this.activeTab = tab;
  }

  onImageInputChange(files: FileList) {
    let me = this;
    let file = files[0];
  }
}
