import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-how-works',
  templateUrl: './how-works.component.html',
  styleUrls: ['./how-works.component.scss'],
  host: {
    class: 'py-64 block',
  },
})
export class HowWorksComponent implements OnInit {
  @Input() data: any;
  constructor() {}

  ngOnInit(): void {}
}
