<div class="flex bg-white rounded-8 p-32 gap-32 relative">
  <button (click)="closeVideo()" class="absolute top-8 right-8">
    <app-icon [icon]="'close'" [fill]="'blue'" class="w-16 h-16"></app-icon>
  </button>
  <div class="w-320 max-w-320 mr-32">
    <div
      class="w-full h-112 stepOne rounded-8 flex items-center px-16"
      [ngClass]="{ 'active-step': activeSection == 1 }"
    >
      <div class="w-64 mr-16 min-w-64">
        <p class="text-xs">Time left</p>
        <p class="text-xl font-bold">
          <ng-container *ngIf="activeSection == 1">
            {{ 20 - timer }}
          </ng-container>
          <ng-container *ngIf="activeSection == 0">
            20
          </ng-container>
          <ng-container *ngIf="activeSection > 1">
            0
          </ng-container>
          <span class="text-sm text-regular">sec</span>
        </p>
      </div>
      <span class="text-lg font-bold">Introduce yourself</span>
    </div>
    <div
      class="w-full h-112 stepTwo rounded-8 flex items-center text-lg font-bold px-16"
      [ngClass]="{ 'active-step': activeSection == 2 }"
    >
      <div class="w-64 mr-16 min-w-64">
        <p class="text-xs">Time left</p>
        <p class="text-xl font-bold">
          <ng-container *ngIf="activeSection == 2">
            {{ 40 - timer }}
          </ng-container>
          <ng-container *ngIf="activeSection < 2">
            20
          </ng-container>
          <ng-container *ngIf="activeSection > 2">
            0
          </ng-container>
          <span class="text-sm text-regular">sec</span>
        </p>
      </div>
      <span class="text-lg font-bold">
        What kind of job are you looking for?</span
      >
    </div>
    <div
      class="w-full h-112 stepThree rounded-8 flex items-center text-lg font-bold px-16"
      [ngClass]="{ 'active-step': activeSection == 3 }"
    >
      <div class="w-64 mr-16 min-w-64">
        <p class="text-xs">Time left</p>
        <p class="text-xl font-bold">
          <ng-container *ngIf="activeSection == 3">
            {{ 60 - timer }}
          </ng-container>
          <ng-container *ngIf="activeSection != 3">
            20
          </ng-container>
          <span class="text-sm text-regular">sec</span>
        </p>
      </div>
      <span class="text-lg font-bold"> Why should you be hired?</span>
    </div>
  </div>
  <div class="">
    <video id="video_{{ idx }}" class="video-js vjs-default-skin"></video>
  </div>
</div>
