<div class="container mx-auto grid grid-cols-8 gap-24 py-24 min-h-full">
  <div class="col-span-2">
    <div class="bg-white rounded-8 py-32 mb-24">
      <h4 class="text-lg font-bold mb-24 text-center">Add company logo</h4>
      <div class="edit-user-image w-136 h-136 rounded-1/2 relative mx-auto">
        <div class="img-container">
          <img
            src="http://see2hire.com/{{ profile.logo }}"
            *ngIf="'/public/user_images/' != profile.image"
            alt=""
          />
        </div>
        <button>
          <input
            type="file"
            #imageInput
            (change)="onImageInputChange($event.target.files)"
            accept="image/*"
          />
          <app-icon
            [icon]="'plus'"
            [fill]="'blue'"
            *ngIf="'/admin_assets/no-image.png' == profile.logo"
            class="w-16 h-16"
          >
          </app-icon>

          <app-icon
            [icon]="'edit'"
            [fill]="'blue'"
            *ngIf="'/admin_assets/no-image.png' != profile.logo"
            class="w-16 h-16"
          >
          </app-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-span-4 bg-white rounded-8 p-24 h-full">
    <h3 class="text-xl font-bold pb-16 border-b border-border">
      Company Settings
    </h3>
    <div class="grid grid-cols-4 gap-24 h-full">
      <ul class="col-span-1 profile-menu mt-16">
        <li
          class="cursor-pointer py-8 font-semibold"
          (click)="changeTab('account')"
          [ngClass]="{ active: activeTab == 'account' }"
        >
          Account
        </li>
        <li
          class="cursor-pointer py-8 font-semibold"
          (click)="changeTab('company')"
          [ngClass]="{ active: activeTab == 'company' }"
        >
          Company
        </li>
        <li
          class="cursor-pointer py-8 font-semibold"
          (click)="changeTab('billing')"
          [ngClass]="{ active: activeTab == 'billing' }"
        >
          Billing
        </li>
        <li
          class="cursor-pointer py-8 font-semibold"
          (click)="changeTab('membership')"
          [ngClass]="{ active: activeTab == 'membership' }"
        >
          Membership
        </li>
      </ul>
      <div
        class="col-span-3 overflow-y-auto overflow-x-hidden relative h-full flex"
      >
        <div class="w-full" *ngIf="activeTab == 'account'">
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-16"
          >
            <h2 class="text-lg font-bold">Account Details</h2>
            <button>
              <app-icon
                [icon]="'edit'"
                [fill]="'blue'"
                class="w-20 h-20"
              ></app-icon>
            </button>
          </div>
          <form class="grid grid-cols-2 gap-24">
            <div class="form-group">
              <label>Email</label>
              <input class="px-16" type="email" formControlName="email" />
            </div>
          </form>
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-32"
          >
            <h2 class="text-lg font-bold">Password</h2>
            <button>
              <app-icon
                [icon]="'edit'"
                [fill]="'blue'"
                class="w-20 h-20"
              ></app-icon>
            </button>
          </div>
          <form action="" class="grid grid-cols-2 mb-32 gap-24">
            <div class="form-group">
              <label>Password</label>
              <input class="px-16" type="password" placeholder="Password" />
            </div>
            <div></div>
            <div class="form-group" [@fadeUp]>
              <label>New Password</label>
              <input class="px-16" type="password" placeholder="password" />
            </div>
            <div class="form-group" [@fadeUp]>
              <label>Repeat password</label>
              <input
                class="px-16"
                type="password"
                placeholder="repeat password"
              />
            </div>
          </form>

          <p>{{ profile.name }}</p>
        </div>
        <div class="w-full" *ngIf="activeTab == 'company'">
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-16"
          >
            <h2 class="text-lg font-bold">Company information</h2>
          </div>
          <div class="grid grid-cols-2 gap-24">
            <div class="form-group">
              <label for="">Company Name</label>
              <input
                type="text"
                placeholder="Enter Company Name"
                class="px-16"
              />
            </div>
            <div class="form-group">
              <label for="">SEO Name</label>
              <input
                type="text"
                placeholder="Enter Company SEO"
                class="px-16"
              />
            </div>
            <div class="form-group select">
              <label for="">Industry</label>
              <input type="text" class="px-16" placeholder="Select Industry" />
            </div>
            <div class="form-group select">
              <label for="">Ownership Type</label>
              <input
                type="text"
                class="px-16"
                placeholder="Select Ownership Type"
              />
            </div>
            <div class="form-group col-span-2">
              <label for="">Address</label>
              <input type="text" class="px-16" placeholder="Enter Location" />
            </div>
            <div class="form-group select">
              <label for="">No of Office</label>
              <input
                type="text"
                placeholder="Select Num of Offices"
                class="px-16"
              />
            </div>
            <div class="form-group select">
              <label for="">No of Employees</label>
              <input
                type="text"
                placeholder="Select Num of Employees"
                class="px-16"
              />
            </div>
          </div>
        </div>
        <div class="w-full" *ngIf="activeTab == 'billing'">
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-16"
          >
            <h2 class="text-lg font-bold">Billing</h2>
          </div>
          <div class="flex justify-around">
            <div
              class="bg-gray-body rounded-8 py-24 w-120 flex flex-col justify-center items-center method active"
            >
              <app-icon [icon]="'paypal'" class="w-40 h-40 mb-16"></app-icon>
              <p class="font-bold">PayPal</p>
            </div>
            <div
              class="bg-gray-body rounded-8 py-24 w-120 flex flex-col justify-center items-center method"
            >
              <app-icon [icon]="'card'" class="w-40 h-40 mb-16"></app-icon>
              <p class="font-bold">Credit Card</p>
            </div>
          </div>
          <div class="border-b border-border mt-32"></div>
          <ng-container>
            <img src="assets/img/paypal.svg" alt="" class="w-120 mt-48" />
            <div class="grid grid-cols-3 gap-24 mt-24">
              <div class="form-group col-span-2">
                <label for="">Your PayPal Account</label>
                <input type="email" class="px-16" placeholder="PayPal email" />
              </div>
              <button class="btn btn-flat self-end col-span-1">Setup</button>
            </div>
            <p class="mt-16">
              You’ll be redirected to PayPal to link a verified account.
            </p>
          </ng-container>
          <ng-container>
            <div class="grid grid-cols-3 gap-24 mt-32">
              <div class="form-group col-span-2">
                <label for="">Card Number</label>
                <input type="text" class="px-16" />
              </div>
              <div class="form-group col-span-1">
                <label for="">Security Code</label>
                <input type="text" class="px-16" />
              </div>
            </div>
            <div class="grid grid-cols-2 gap-24 my-24">
              <div class="form-group select">
                <label for="">Year</label>
                <input type="text" placeholder="YY" class="px-16" />
              </div>
              <div class="form-group select">
                <label for="">Month</label>
                <input type="text" placeholder="MM" class="px-16" />
              </div>
            </div>
          </ng-container>
        </div>

        <div class="w-full" *ngIf="activeTab == 'membership'">
          <div
            class="flex justify-between pb-8 border-b border-border items-center mb-24 mt-16"
          >
            <h2 class="text-lg font-bold">Membership</h2>
          </div>
          <div class="grid grid-cols-2 gap-48">
            <div class="bg-gray-body relative rounded-8 pt-48 pb-32 mt-48 z-0">
              <div
                class="bg-gray-body absolute -top-48 left-1/2 transform -translate-x-1/2 w-100 h-100 rounded-1/2 flex justify-center items-center"
              >
                <app-icon
                  [icon]="'basic'"
                  fill="'blue'"
                  class="w-72 h-72 opacity-50"
                ></app-icon>
              </div>
              <h2 class="text-center uppercase font-semibold text-lg mb-16">
                Basic
              </h2>
              <h3 class="text-center mb-8">
                <span class="text-xl">$</span
                ><span class="text-5xl font-bold">9</span
                ><span class="text-2xl" style="vertical-align: 17px">.99</span>
              </h3>
              <h4 class="text-center mb-16">
                <p class="font-semibold">1</p>
                <p class="text-xs uppercase">Tokens</p>
              </h4>
              <button class="btn btn-blue block mx-auto">Subscribe</button>
            </div>
            <div class="bg-gray-body relative rounded-8 pt-48 pb-32 mt-48 z-0">
              <div
                class="bg-gray-body absolute -top-48 left-1/2 transform -translate-x-1/2 w-100 h-100 rounded-1/2 flex justify-center items-center"
              >
                <app-icon
                  [icon]="'premium'"
                  fill="'blue'"
                  class="w-72 h-72 opacity-50"
                ></app-icon>
              </div>
              <h2 class="text-center uppercase font-semibold text-lg mb-16">
                Premium
              </h2>
              <h3 class="text-center mb-8">
                <span class="text-xl">$</span
                ><span class="text-5xl font-bold">19</span
                ><span class="text-2xl" style="vertical-align: 17px">.99</span>
              </h3>
              <h4 class="text-center mb-16">
                <p class="font-semibold">3</p>
                <p class="text-xs uppercase">Tokens</p>
              </h4>
              <button class="btn btn-blue block mx-auto">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-span-2 bg-white rounded-8 p-24"></div>
</div>
