import { Component, OnInit } from '@angular/core';
import { PersistanceService } from 'src/app/services/persistance-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import {
  useAnimation,
  transition,
  trigger,
  style,
  animate,
} from '@angular/animations';
import { fadeUp, slideInOut } from 'src/app/app.animations';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  host: {
    class: 'block h-main',
  },
  animations: [slideInOut, fadeUp],
})
export class ProfileComponent implements OnInit {
  userType = 'user';
  token = '';
  loaded = false;
  profileData: any;
  hasRecordVideoPanel = false;
  activeTab = 'account';
  imageFile: any;
  isEditMode = false;
  isEditAccountDetails = false;
  switchGender = false;
  switchCountry = false;
  switchState = false;
  switchCity = false;
  stateErr = false;
  cityErr = false;

  openSkillDD = false;
  selectedSkillObj = null;
  selectedSkill = '';
  selectedSkillId = -1;
  openExpSkillDD = false;
  selectedExpSkillObj = null;
  selectedExpSkill = '';
  selectedExpSkillId = -1;

  openLanguagesDD = false;
  selectedLangObj = null;
  selectedLang = '';
  selectedLangId = -1;

  openLanguagesExpDD = false;
  selectedLangExpObj = null;
  selectedLangExp = '';
  selectedLangExpId = -1;

  genders = [];
  countries = [];
  states = [];
  cities = [];
  skills = [];
  languages = [];
  nonFilterLanguages = [];
  languagesExp = [];
  nonFilterSkills = [];
  langExist = false;
  skillExist = false;
  skillExps = ['1 Year', '2 Years', '10 Years'];

  accountDetailsForm = new FormGroup({
    email: new FormControl('', [
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
    ]),
    phone: new FormControl('', [
      Validators.pattern(
        '(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))s*[)]?[-s.]?[(]?[0-9]{1,3}[)]?([-s.]?[0-9]{3})([-s.]?[0-9]{3,4})'
      ),
    ]),
    mobile: new FormControl('', [
      Validators.pattern(
        '(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))s*[)]?[-s.]?[(]?[0-9]{1,3}[)]?([-s.]?[0-9]{3})([-s.]?[0-9]{3,4})'
      ),
    ]),
  });

  constructor(
    private persistanceService: PersistanceService,
    private authService: AuthService
  ) {}

  loadProfile() {
    this.authService.getTalentProfile(this.token).subscribe((res) => {
      this.profileData = res['data'];
      this.loaded = true;
      this.loadStates(this.profileData.country_id);
      this.loadCities(this.profileData.state_id);
    });
  }

  loadStates(country_id) {
    this.authService.getStates(this.token, country_id).subscribe((res) => {
      this.states = res['data'];
    });
  }

  loadCities(state_id) {
    this.authService.getCities(this.token, state_id).subscribe((res) => {
      this.cities = res['data'];
    });
  }

  loadSkills() {
    this.authService.getSkills(this.token).subscribe((res) => {
      this.skills = res['data'];
      this.nonFilterSkills = res['data'];
    });
  }

  loadSkillsExpirience() {
    this.authService.getSkillsExpirience(this.token).subscribe((res) => {
      this.skillExps = res['data'];
      debugger;
    });
  }

  loadLanguage() {
    this.authService.getLanguages(this.token).subscribe((res) => {
      this.languages = res['data'];
      this.nonFilterLanguages = res['data'];
    });
  }

  loadLanguageExp() {
    this.authService.getLanguageExp(this.token).subscribe((res) => {
      this.languagesExp = res['data'];
      debugger;
    });
  }

  ngOnInit(): void {
    this.userType = this.persistanceService.get('userType') || 'user';
    this.token = this.persistanceService.get('token') || '';

    if (this.userType == 'user') {
      this.loadProfile();
      this.disableEnableForm(false);
      this.authService.getGenders(this.token).subscribe((res) => {
        this.genders = res['data'];
      });

      this.authService.getCountry(this.token).subscribe((res) => {
        this.countries = res['data'];
      });

      this.loadSkills();
      this.loadSkillsExpirience();
      this.loadLanguage();
      this.loadLanguageExp();
    } else if (this.userType == 'company') {
      this.authService.getCompanyProfile(this.token).subscribe((res) => {
        this.profileData = res['data'];
        this.loaded = true;
      });
    }
  }

  onImageInputChange(files: FileList) {
    let me = this;
    let file = files[0];
    this.authService.updateProfileImage(this.token, file).subscribe(
      (res) => {
        this.loadProfile();
      },
      (error) => {
        alert(error);
      }
    );
    // var that = this;
    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function () {
    //   console.log(reader.result);
    //   that.imageFile = reader.result;
    //   that.authService
    //     .updateImage64(that.token, that.imageFile)
    //     .subscribe((res) => {
    //       debugger;
    //     });
    // };
    // reader.onerror = function (error) {
    //   console.log('Error: ', error);
    // };
  }

  recordVideoPanel() {
    this.hasRecordVideoPanel = true;
  }

  changeTab(tab) {
    this.activeTab = tab;
  }

  editMode() {
    this.isEditMode = true;
    this.disableEnableForm(true);
  }

  cancelEdit() {
    this.isEditMode = false;
    this.loadProfile();
    this.disableEnableForm(false);
  }

  updateAcc() {
    if (this.profileData.state_id == 0) {
      this.stateErr = true;
      return;
    }
    if (this.profileData.city_id == 0) {
      this.cityErr = true;
      return;
    }
    this.authService.updateProfile(this.token, this.profileData).subscribe(
      (res) => {
        this.isEditMode = false;
      },
      (error) => {}
    );
  }

  openSkills(event) {
    event.stopPropagation();
    this.openSkillDD = !this.openSkillDD;
    this.openExpSkillDD = false;
  }

  openLanguages(event) {
    event.stopPropagation();
    this.openLanguagesDD = true;
    this.openLanguagesExpDD = false;
  }

  openLanguagesExp(event) {
    event.stopPropagation();
    this.openLanguagesDD = false;
    this.openLanguagesExpDD = true;
  }

  openSkillExps(event) {
    event.stopPropagation();
    this.openSkillDD = false;
    this.openExpSkillDD = !this.openExpSkillDD;
  }

  openGender(event) {
    event.stopPropagation();
    this.switchGender = !this.switchGender;
    this.switchCountry = false;
    this.switchState = false;
    this.switchCity = false;
  }

  selectGen(gen) {
    this.profileData.gender = gen.gender;
    this.profileData.gender_id = gen.gender_id;
    this.switchGender = false;
  }

  openCountry(event) {
    event.stopPropagation();
    this.switchGender = false;
    this.switchCountry = !this.switchCountry;
    this.switchState = false;
    this.switchCity = false;
  }

  selectCountry(country) {
    this.profileData.country = country;
    this.profileData.country_id = country.country_id;
    this.loadStates(country.country_id);
    this.profileData.state.state = 'Select state';
    this.profileData.state_id = 0;
    this.switchCountry = false;
  }

  openState(event) {
    event.stopPropagation();
    this.switchGender = false;
    this.switchCountry = false;
    this.switchState = !this.switchState;
    this.switchCity = false;
  }

  selectState(state) {
    this.stateErr = false;
    this.profileData.state = state;
    this.profileData.state_id = state.state_id;
    this.switchState = false;
    this.loadCities(state.state_id);
    this.profileData.city.city = 'Select city';
    this.profileData.city_id = 0;
  }

  openCity(event) {
    event.stopPropagation();
    this.switchGender = false;
    this.switchCountry = false;
    this.switchState = false;
    this.switchCity = !this.switchCity;
  }

  selectCity(city) {
    this.cityErr = false;
    this.profileData.city = city;
    this.profileData.city_id = city.city_id;
    this.switchCity = false;
  }

  filterSkills() {
    this.skills = this.nonFilterSkills.filter(
      (x) =>
        x['job_skill']
          .toLowerCase()
          .indexOf(this.selectedSkill.toLowerCase()) != -1
    );
  }

  filterLang() {
    this.languages = this.nonFilterLanguages.filter(
      (x) =>
        x['lang'].toLowerCase().indexOf(this.selectedLang.toLowerCase()) != -1
    );
  }

  selectSkill(skill) {
    this.openSkillDD = false;
    this.selectedSkillObj = skill;
    this.selectedSkill = skill.job_skill;
    this.selectedSkillId = skill.id;
  }

  selectSkillExps(skillExps) {
    this.openExpSkillDD = false;
    this.selectedExpSkillObj = skillExps;
    this.selectedExpSkill = skillExps.job_experience;
    this.selectedExpSkillId = skillExps.id;
  }

  selectLanguage(lang) {
    this.openLanguagesDD = false;
    this.selectedLangObj = lang;
    this.selectedLang = lang.lang;
    this.selectedLangId = lang.id;
  }

  selectLangExps(langExps) {
    this.openLanguagesExpDD = false;
    this.selectedLangExpObj = langExps;
    this.selectedLangExp = langExps.language_level;
    this.selectedLangExpId = langExps.id;
  }

  closeRecordVideo() {
    this.hasRecordVideoPanel = false;
  }

  uploadRecordedVideo() {
    this.hasRecordVideoPanel = false;
    this.loadProfile();
  }

  disableEnableForm(isEnable) {
    if (isEnable) {
      this.accountDetailsForm.controls['email'].enable();
      this.accountDetailsForm.controls['phone'].enable();
      this.accountDetailsForm.controls['mobile'].enable();
    } else {
      this.accountDetailsForm.controls['email'].disable();
      this.accountDetailsForm.controls['phone'].disable();
      this.accountDetailsForm.controls['mobile'].disable();
    }
  }

  closeAllDropDown() {
    this.switchGender = false;
    this.switchCountry = false;
    this.switchState = false;
    this.switchCity = false;
    this.openSkillDD = false;
    this.openExpSkillDD = false;
  }

  addSkill() {
    if (this.selectedExpSkillObj != null && this.selectedSkillObj != null) {
      if (
        this.profileData.profileSkills.find(
          (x) => x.job_skill == this.selectedSkillObj.job_skill
        ) == undefined
      ) {
        this.profileData.profileSkills.push(this.selectedSkillObj);
        this.selectedSkill = '';
        this.selectedExpSkill = '';
        this.skillExist = false;
      } else {
        this.skillExist = true;
      }
    }
  }

  addLang() {
    if (this.selectedLangObj != null && this.selectedLangExpObj != null) {
      if (
        this.profileData.languages.find(
          (x) => x.language_id == this.selectedLangObj.id
        ) == undefined
      ) {
        this.authService
          .addLanguages(this.token, this.selectedLangId, this.selectedLangExpId)
          .subscribe((res) => {
            this.profileData.languages.push({
              language: this.selectedLangObj,
              language_id: this.selectedLangId,
              language_level: this.selectedLangExpObj,
              language_level_id: this.selectedLangExpId,
            });
          });

        this.selectedLang = '';
        this.selectedLangExp = '';
        this.langExist = false;
      } else {
        this.langExist = true;
      }
    }
  }

  removeSkill(skill) {
    debugger;
    var id = this.profileData.profileSkills.findIndex((x) => x.id == skill.id);
    this.profileData.profileSkills.splice(id, 1);
  }

  removeLang(lang) {
    debugger;
    this.authService.deleteLanguages(this.token, lang.id).subscribe((res) => {
      var id = this.profileData.languages.findIndex(
        (x) => x.language_id == lang.language_id
      );
      this.profileData.languages.splice(id, 1);
    });
  }
}
