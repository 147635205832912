import {Component, OnInit, Input} from "@angular/core";

@Component({
  selector: "app-job-description",
  templateUrl: "./job-description.component.html",
  styleUrls: ["./job-description.component.scss"],
  host: {
    class: "bg-white block rounded-8 p-16 relative mb-16 cursor-pointer",
  },
})
export class JobDescriptionComponent implements OnInit {
  @Input() job: any;
  constructor() {}

  ngOnInit(): void {}

  openMore(event) {
    event.stopImmediatePropagation();
    if (this.job.more == null) {
      this.job.more = true;
    } else {
      this.job.more = !this.job.more;
    }
  }
}
