import { Component, OnInit } from '@angular/core';
import { PersistanceService } from './services/persistance-service.service';
import { Router } from '@angular/router';

import videojs from 'video.js';
import * as RecordRTC from 'recordrtc';
import * as Record from 'videojs-record/dist/videojs.record.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    class: 'block h-full overflow-y-auto',
  },
})
export class AppComponent implements OnInit {
  title = 'see2hire';
  lineOne = 'Search Talent through an';
  lineTwo = 'interactive Experience';
  token = '';

  constructor(
    private persistanceService: PersistanceService,
    public router: Router
  ) {}

  ngOnInit() {
    this.token = this.persistanceService.get('token') || '';
  }

  refreshToken() {
    this.token = this.persistanceService.get('token') || '';
  }

  ngAfterContentChecked() {
    this.lineOne = this.persistanceService.get('headerLineOne') || '';
    this.lineTwo = this.persistanceService.get('headerLineTwo') || '';

    this.token = this.persistanceService.get('token') || '';
  }

  ngAfterViewChecked(): void {
    this.token = this.persistanceService.get('token') || '';
  }
}
