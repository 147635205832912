<app-logo
  class="w-120 mt-8 ml-16"
  [show]="false"
  [routerLink]="['/main']"
></app-logo>
<!-- <button (click)="logOut()" class="btn btn-inverted absolute top-10 right-16">Logout</button> -->

<div class="w-40 h-40 rounded-1/2 relative mr-16 pointer user-menu">
  <app-avatar [avatarUrl]="avatar" class="w-40 h-40"></app-avatar>
  <ul class="absolute top-full right-0 bg-white shadow-sm rounded-8">
    <li
      class="px-16 py-8 flex items-center"
      [routerLink]="['/profile']"
      routerLinkActive="router-link-active"
    >
      <app-icon
        [icon]="'profile'"
        class="mr-16 w-20 h-20 opacity-75"
      ></app-icon>
      <span>Profile</span>
    </li>
    <li class="px-16 py-8 flex items-center" *ngIf="userType == 'user'">
      <app-icon
        [icon]="'dashboard'"
        class="mr-16 w-20 h-20 opacity-75"
      ></app-icon>
      <span>Public Profile</span>
    </li>

    <li class="px-16 py-8 flex items-center" *ngIf="userType == 'company'">
      <app-icon
        [icon]="'dashboard'"
        class="mr-16 w-20 h-20 opacity-75"
      ></app-icon>
      <span>Public profile</span>
    </li>
    <li class="px-16 py-8 flex items-center" *ngIf="userType == 'company'">
      <app-icon [icon]="'users'" class="mr-16 w-20 h-20 opacity-75"></app-icon>
      <span>Company talents </span>
    </li>
    <li class="px-16">
      <button (click)="logOut()" class="flex items-center py-8 w-full">
        <app-icon
          [icon]="'logout'"
          class="mr-16 w-20 h-20 opacity-75"
        ></app-icon>
        <span>Logout</span>
      </button>
    </li>
  </ul>
</div>
