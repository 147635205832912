import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-talent-summary',
  templateUrl: './talent-summary.component.html',
  styleUrls: ['./talent-summary.component.scss'],
})
export class TalentSummaryComponent implements OnInit {
  @Input() talentDetails: any;
  constructor() {}

  ngOnInit(): void {}
}
