import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PersistanceService } from 'src/app/services/persistance-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent implements OnInit {
  @Output() logOutAction: EventEmitter<any> = new EventEmitter();
  userType = 'user';
  avatar = '';
  constructor(
    private persistanceService: PersistanceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userType = this.persistanceService.get('userType') || 'user';
    debugger;
    this.avatar = this.persistanceService.get('imgHeader');
  }

  logOut() {
    this.persistanceService.set('imgHeader', '');
    this.persistanceService.set('token', '');
    this.persistanceService.set('userType', '');
    this.logOutAction.emit();
    this.router.navigateByUrl('/main');
  }
}
