<div class="flex justify-between items-center border-b border-gray-body-darken">
  <div class="flex mb-16">
    <div class="w-56 h-56 overflow-hidden rounded-1/2">
      <img
        src="{{ jobDetails.company.img }}"
        alt=""
        class="w-full h-full object-cover"
      />
    </div>
    <div class="ml-16">
      <h4 class="text-lg">{{ jobDetails.company.name }}</h4>
      <p class="flex items-center text-sm">
        <app-icon [icon]="'location'" class="w-16 h-16 mr-8"></app-icon> {{
        jobDetails.location }}
      </p>
    </div>
  </div>
  <button class="btn btn-stroke btn-sm">Apply</button>
</div>
<div class="mt-16">
  {{ jobDetails.description.shortInfo }}
</div>
<div class="mt-16">
  <p class="font-bold">Responsibilities</p>
  {{ jobDetails.description.responsibilities }}
</div>
<div>
  <p class="font-bold mt-16">Requirements</p>
  {{ jobDetails.description.requirements }}
</div>
<div>
  <p class="font-bold mt-16">Experience</p>
  {{ jobDetails.description.experience }}
</div>
