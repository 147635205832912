import {Component, OnInit} from "@angular/core";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
  host: {
    class:
      "w-full h-full absolute z-100 bg-white left-0 top-0 flex justify-center items-center",
  },
})
export class LoaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
