<div class="user-type flex justify-center py-40" *ngIf="token == ''">
  <button
    (click)="switchTallent()"
    class="main-tab-btn"
    [ngClass]="{ active: userType == 'user' }"
  >
    <app-icon [icon]="'talent'"></app-icon>
    <label>Talent</label>
  </button>
  <button
    (click)="switchCompany()"
    class="main-tab-btn active"
    [ngClass]="{ active: userType == 'company' }"
  >
    <app-icon [icon]="'company'"></app-icon>
    <label>Company</label>
  </button>
</div>

<app-tallent-content *ngIf="userType == 'user'"></app-tallent-content>
<app-company-content *ngIf="userType == 'company'"></app-company-content>
