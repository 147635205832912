<app-loader *ngIf="hasLoader"></app-loader>
<div
  class="w-530 flex flex-col justify-right items-center h-loginh md:h-full bg-white z-2 transition duration-300 absolute top-64 md:top-0 right-0 z-10"
>
  <div class="flex w-full">
    <button
      (click)="switchTallent()"
      class="login-tab"
      [ngClass]="{ active: userType == 'user' }"
    >
      <app-icon [icon]="'talent'"></app-icon>
      <label>Talent</label>
    </button>
    <button
      (click)="switchCompany()"
      class="login-tab active"
      [ngClass]="{ active: userType == 'company' }"
    >
      <app-icon [icon]="'company'"></app-icon>
      <label>Company</label>
    </button>
  </div>
  <div class="flex-grow flex flex-col items-center justify-center">
    <div
      class="w-full md:w-256"
      *ngIf="userType == 'user'"
      [ngClass]="{ active: userType == 'user' }"
      [formGroup]="form"
    >
      <h2 class="text-xl font-semibold text-center mb-48">Login Talent</h2>
      <div class="form-group mb-16 w-full">
        <input
          type="email"
          placeholder="Email"
          class="px-16"
          formControlName="username"
        />
      </div>

      <div class="form-group mb-32 w-full">
        <app-icon
          *ngIf="talentTypePassword == 'password'"
          [icon]="'eye'"
          class="absolute right-16 top-8 mt-3"
          (click)="showTalentTypePassword()"
        ></app-icon>
        <app-icon
          *ngIf="talentTypePassword == 'text'"
          [icon]="'eye-no'"
          class="absolute right-16 top-8"
          (click)="showTalentTypePassword()"
        ></app-icon>
        <input
          type="{{ talentTypePassword }}"
          placeholder="Password"
          class="px-16"
          formControlName="password"
        />
      </div>
      <div style="color: red;" *ngIf="talentLoginError != ''">
        {{ talentLoginError }}
      </div>
      <button class="btn btn-blue w-full" (click)="loginTalent()">Login</button>
    </div>

    <div
      class="w-full md:w-256"
      *ngIf="userType == 'company'"
      [ngClass]="{ active: userType == 'company' }"
      [formGroup]="form"
    >
      <h2 class="text-center text-xl font-semibold mb-48">Login Company</h2>
      <div class="form-group mb-16">
        <input
          type="email"
          placeholder="Email"
          class="px-16"
          formControlName="username"
        />
      </div>
      <div class="form-group mb-32">
        <app-icon
          *ngIf="companyTypePassword == 'password'"
          [icon]="'eye'"
          class="absolute right-16 top-8 mt-3"
          (click)="showCompanyTypePassword()"
        ></app-icon>
        <app-icon
          *ngIf="companyTypePassword == 'text'"
          [icon]="'eye-no'"
          class="absolute right-16 top-8"
          (click)="showCompanyTypePassword()"
        ></app-icon>
        <input
          type="{{ companyTypePassword }}"
          placeholder="Password"
          class="px-16"
          formControlName="password"
        />
      </div>
      <div style="color: red;" *ngIf="companyLoginError != ''">
        {{ companyLoginError }}
      </div>
      <button class="btn btn-blue w-full" (click)="loginCompany()">
        Login
      </button>
    </div>
    <p class="mt-32">
      New User?
      <span
        [routerLink]="['/register']"
        routerLinkActive="router-link-active"
        class="link"
        >Register Here</span
      >
    </p>
    <p>
      Forgot Your Password??
      <span
        [routerLink]="['/forgot']"
        routerLinkActive="router-link-active"
        class="link"
        >Click Here</span
      >
    </p>
  </div>
  <p class="text-xs opacity-50 mb-16">
    Copyright © 2020 See2hire. All Rights Reserved.
  </p>
  <div class="logo">
    <app-logo></app-logo>
    <h1
      class="text-2xl text-white uppercase text-right tracking-title mt-48"
      *ngIf="userType == 'user'"
    >
      Find best job in <br />
      interactive Experience
    </h1>

    <h1
      class="text-2xl text-white uppercase text-right tracking-title mt-48"
      *ngIf="userType == 'company'"
    >
      Search Talent through an <br />
      interactive Experience
    </h1>
  </div>
</div>
